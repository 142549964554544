<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- :to="{ path: item.path ? item.path : '/' }" -->
      <el-breadcrumb-item
        v-for="item in levelList"
        :key="item.path"
      >
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levelList: [],
    };
  },
  created() {},
  mounted() {
    this.levelList = this.$route.matched.filter((item) => item.name);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
</style>
