import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as actions from './actions';
import * as mutations from './mutations';
Vue.use(Vuex);

//getter相当于计算属性
const getters = {
  isAdd: state => state.isAdd,
  searchContent: state => state.searchContent,
  searchList: state => state.searchList,
  wsMsg: state => state.wsMsg,
  ws: state => state.ws,
  idCollection: state => state.idCollection,
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters
});