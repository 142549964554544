/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:49:43 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-Tu 09:25:43
 */
import Vue from 'vue'
import { Loading } from 'element-ui'
let loading;
let needLoadingRequestCount = 0;//声明一个变量
//开始 加载loading
const startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...', //可以自定义文字
    spinner: 'el-icon-loading', //自定义加载图标类名
    background: 'rgba(0, 0, 0, .6)' //遮罩层背景色
  })
}
function endLoading() {
  Vue.nextTick(function() {
    // DOM 更新了
    loading.close()
  })
}
// showLoading() 与 tryCloseLoading() 目的是合并同一页面多个请求触发loading
export const showLoading = () => {
  if (needLoadingRequestCount === 0) {//当等于0时证明第一次请求 这时开启loading
    startLoading()
  }
  needLoadingRequestCount++//全局变量值++ 
}

export const hideLoading = () => {
  setTimeout(tryCloseLoading, 300)
}

function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return//小于等于0 证明没有开启loading 此时return
  needLoadingRequestCount--//正常响应后 全局变量 --
  if (needLoadingRequestCount === 0) {//等于0 时证明全部加载完毕 此时结束loading 加载
    endLoading()
  }
}