var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answer",style:({ marginTop: _vm.isWxApplets ? '46px' : '' })},[(_vm.isWxApplets)?[_c('van-nav-bar',{attrs:{"title":"练习","fixed":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}})]:_vm._e(),_c('div',{},[_c('div',{staticClass:"cont"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.number)+"/"+_vm._s(_vm.list.length))]),_c('i',{staticClass:"el-icon-tickets el-icon",on:{"click":function($event){return _vm.clickSubject()}}})]),_c('el-row',[_c('el-col',{attrs:{"span":24}},[(_vm.list.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.list),function(li,i){return _c('div',{key:i,staticClass:"li"},[(_vm.number == i + 1)?_c('div',{staticClass:"paperBank"},[_c('div',{staticClass:"questionTitle"},[_c('p',{staticClass:"tmtype"},[_c('span',{class:li.QuestionCategory == 0
                          ? 'bgc_3A78F9'
                          : li.QuestionCategory == 1
                          ? 'bgc_FBB03B'
                          : li.QuestionCategory == 2
                          ? 'bgc_00AE62'
                          : li.QuestionCategory == 3
                          ? 'bgc_B938B5'
                          : li.QuestionCategory == 4
                          ? 'bgc_9682EC'
                          : 'bgc_37E6CF'},[_vm._v(" "+_vm._s(li.QuestionCategory == 0 ? "单选" : li.QuestionCategory == 1 ? "多选" : li.QuestionCategory == 2 ? "判断" : li.QuestionCategory == 3 ? "填空" : li.QuestionCategory == 4 ? "简答" : "论述")+" ")]),_c('span',[_vm._v("题目"+_vm._s(i + 1)+"："+_vm._s(li.QuestionTitle))])]),_c('div',{staticClass:"correction",on:{"click":function($event){return _vm.correction(li)}}},[_vm._v(" 纠错 "),_c('i',{staticClass:"el-icon-edit-outline"})])]),(li.QuestionCategory === 0)?_c('el-radio-group',{on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],2):(li.QuestionCategory === 1)?_c('el-checkbox-group',{on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-checkbox',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],2):(li.QuestionCategory === 2)?_c('el-radio-group',{on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],2):(li.QuestionCategory === 3)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入填空,多个填空以中文；隔开"},on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],1):(li.QuestionCategory === 4)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入简答"},on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],1):(li.QuestionCategory === 5)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入论述"},on:{"change":function($event){return _vm.handChange(li)}},model:{value:(li.checkList),callback:function ($$v) {_vm.$set(li, "checkList", $$v)},expression:"li.checkList"}}),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                          li.QuestionAnalysis == null
                            ? '无'
                            : li.QuestionAnalysis
                        )}})]),_c('div',[_vm._v(" 【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial)+" ")])]):_vm._e()],1):_vm._e()],1):_vm._e()])}),0):_c('div',{staticClass:"list"},[_c('div',{staticClass:"li"},[_c('div',{staticClass:"paperBank"},[_c('no-data')],1)])])])],1),(_vm.list.length > 0)?_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"qhtm"},[_c('el-row',[_c('el-col',{attrs:{"span":8},nativeOn:{"click":function($event){return _vm.lastQuestion()}}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(" 上一题 ")]),_c('el-col',{attrs:{"span":8},nativeOn:{"click":function($event){return _vm.parsClick()}}},[_c('img',{attrs:{"src":require("../../assets/images/lamp.svg"),"width":"16"}}),_vm._v(" 查看答案 ")]),_c('el-col',{attrs:{"span":8},nativeOn:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" 下一题 "),_c('i',{staticClass:"el-icon-arrow-right"})])],1)],1)])],1):_vm._e()],1),_c('div',{staticClass:"immediately"},[_c('el-button',{staticClass:"buy",on:{"click":function($event){return _vm.buy()}}},[_vm._v("立即交卷")])],1)]),(_vm.dialogVisible != false)?_c('dialog-Visible',{attrs:{"type":"1","width":_vm.width,"dialogVisible":_vm.dialogVisible,"obj":_vm.correctionArr},on:{"find":_vm.getFind,"close":function($event){return _vm.getClose()}}}):_vm._e(),_c('van-popup',{style:({ maxHeight: '70%' }),attrs:{"round":"","position":"bottom"},model:{value:(_vm.dialogVisibleSubject),callback:function ($$v) {_vm.dialogVisibleSubject=$$v},expression:"dialogVisibleSubject"}},[_c('div',{staticClass:"subject_cont"},[_c('div',{staticClass:"top"},[_c('div',[_vm._v("题目")]),_c('van-icon',{attrs:{"name":"cross"},on:{"click":_vm.handleCloseSubject}})],1),_c('ul',_vm._l((_vm.list),function(li,i){return _c('li',{key:i,on:{"click":function($event){return _vm.onClick(i + 1)}}},[_c('span',{class:(li.checkList != '' ? 'bgc_active' : '') ||
              (_vm.number == i + 1 ? 'bgc_active1' : '')},[_vm._v(_vm._s(i + 1))])])}),0),_c('div',{staticClass:"xs"},[_c('div',[_c('i'),_vm._v("已做")]),_c('div',[_c('i'),_vm._v("未做")]),_c('div',[_c('i'),_vm._v("选中")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }