/* * @Author: liuzhixiang * @Date: 2021-02-06 14:16:37 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-06 14:16:37 */
<template>
  <!-- ##### Footer Area Start ##### -->
  <footer class="footer-area">
    <!-- Top Footer Area -->
    <div class="top-footer-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Footer Logo -->
            <!--<div class="footer-logo">
                            <a href="index.html">普利文化教育</a>
                        </div>-->
            <!-- Copywrite -->
            <div class="footer1">
              <div
                class="foofont"
                v-for="(item, index) in footerlist"
                :key="index"
                @click="tofooterdetail(index, item)"
              >
                {{ item.text }}
              </div>
            </div>
            <p>
              <a href="#" target="_blank"
                >普利明生源教育信息咨询有限公司　 版权所有 © 2006-2021</a
              >
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="#" target="_blank"
                >统一社会信用代码：91500106MA5YWAT03E
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://beian.miit.gov.cn/"
                >工信部 渝ICP备18016169号</a
              >
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50009802000890"
                >渝公网安备 50009802000890号</a
              >
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      footerlist: [
        { text: '公司介绍' },
        { text: '公司地址' },
        { text: '法律条款' },
        { text: '免责声明' },
      ],
    };
  },
  created () {
    this.init();
  },
  methods: {
    tofooterdetail (index, item) {
      this.$router.push({
        path: "/PCshop/footerdetail",
        query: { index: index, text: item.text },
      });
    },
    init () { },
  },
};
</script>

<style lang="less" scoped>
.footer1 {
  display: flex;
  margin: 0 auto;
  width: 35%;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 10px;
  .foofont {
    cursor: pointer;
    font-size: 18px;
    color: rgb(255 255 255 / 20%);
    &:hover {
      color: #ffffff;
    }
  }
}
.footer {
  min-width: 1200px;
  width: 100%;
  background-color: #373e44;
  color: #ffffff;
  font-size: 16px;
  padding: 25px 0;
  ul {
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    li {
      margin-right: 32px;
      float: left;
    }
  }
  p {
    line-height: 24px;
    color: #cdcccc;
  }
}
</style>
