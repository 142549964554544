/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:33 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-23 11:41:33
 */
<template>
  <div class="Paper">
      <examination-results></examination-results>
  </div>
</template>

<script>
import examinationResults from "@/components/examinationResults.vue";
export default {
  data() {
    return {};
  },
  components: {
    examinationResults
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
  .Paper{
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  }
</style>