// 保存当前数据源
export const saveUserInfo = (state, userInfo) => {
  state.userInfo = userInfo;
  localStorage.setItem("userInfo", userInfo);
};

export const addItem = (state, isAdd) => {
  state.isAdd = isAdd;
};
export const search = (state, searchContent) => {
  state.searchContent = searchContent
};
export const setSearchList = (state, searchList) => {
  state.searchList = searchList
};
export const setWsMsg = (state, wsMsg) => {
  state.wsMsg = wsMsg
};
export const setWs = (state, ws) => {
  state.ws = ws;
}
export const changeshopcar = (state, shopcarlength) => {
  state.shopcarnum = shopcarlength
  localStorage.setItem('shopnum', state.shopcarnum)
}

export const setIdCollection = (state, id) => {
  state.idCollection = id;
}