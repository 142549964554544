<template>
  <div class="myPractice">
    <h3 class="personal_title">
      <!-- <el-select v-model="selectVal" @change="handleChange" placeholder="请选择">
          <el-option
            v-for="item in list"
            :key="item.CourseId"
            :label="item.CourseName"
            :value="item.CourseId+','+item.IsActive">
          </el-option>
        </el-select> -->
      <el-popover
        placement="bottom"
        title=""
        width="200"
        trigger="hover"
        content=""
      >
        <el-scrollbar style="height: 210px">
          <div
            class="courseList"
            v-for="item in list"
            :key="item.CourseId"
            @click="handleChange(item)"
          >
            {{ item.CourseName }}
          </div>
        </el-scrollbar>
        <div slot="reference" class="courseList_hover">{{ selectVal }}</div>
      </el-popover>
      <ul class="practice" v-if="selectVal.includes('英语')">
        <li
          v-for="(par, i) in practices"
          :key="i"
          :class="practicesIndex == par.id ? 'active' : ''"
          @click="practicesClick(par)"
          v-if="selectVal.includes('英语') && par.id > 4"
        >
          <div>
            <i
              :class="
                practicesIndex == par.id
                  ? par.icon + ' c_3A78F9'
                  : par.icon + ' c_707070'
              "
              color="#E2EBFE"
              v-if="par.icon"
            ></i>
            <img
              v-else
              :src="
                require('@/assets/images/' +
                  (practicesIndex == par.id ? par.img + '_checked' : par.img) +
                  '.png')
              "
              width="14"
              alt=""
            />
            <span :class="practicesIndex == par.id ? 'active' : ''">
              {{ par.name }}
            </span>
          </div>
        </li>
      </ul>
      <ul class="practice" v-if="!selectVal.includes('英语')">
        <li
          v-for="(par, i) in practices"
          :key="i"
          :class="practicesIndex == par.id ? 'active' : ''"
          @click="practicesClick(par)"
          v-if="!selectVal.includes('英语') && par.id <= 4"
        >
          <div>
            <i
              :class="
                practicesIndex == par.id
                  ? par.icon + ' c_3A78F9'
                  : par.icon + ' c_707070'
              "
              color="#E2EBFE"
              v-if="par.icon"
            ></i>
            <img
              v-else
              :src="
                require('@/assets/images/' +
                  (practicesIndex == par.id ? par.img + '_checked' : par.img) +
                  '.png')
              "
              width="14"
              alt=""
            />
            <span :class="practicesIndex == par.id ? 'active' : ''">
              {{ par.name }}
            </span>
          </div>
        </li>
      </ul>
    </h3>
    <div v-if="list.length > 0">
      <!-- <div class="personal_top">
        <span
          v-for="(lt, i) in practices"
          :key="i"
          :class="practicesIndex === lt.id ? 'active' : ''"
          @click="practicesClick(lt)"
          >{{ lt.name }}</span
        >
      </div> -->
      <div class="cont">
        <div class="he_10" style="margin: 0 -30px"></div>
        <router-view v-if="list.length > 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { questionBankQueryQuestionCourse } from "@/api/personal";
export default {
  data() {
    return {
      practices: [
        { id: 1, name: "练习记录", icon: "", img: "icon_practice" },
        { id: 2, name: "考试记录", icon: "", img: "icon_examination" },
        { id: 3, name: "刷题记录", icon: "", img: "icon_brush" },
        { id: 4, name: "错题记录", icon: "", img: "icon_wrong" },
        { id: 5, name: "课后练习", icon: "el-icon-tickets" },
        { id: 6, name: "生词本", icon: "", img: "icon_en" },
        { id: 7, name: "句式分析", icon: "", img: "icon_en1" },
      ],
      selectVal: "",
      practicesIndex: 1,
      list: [],
      CourseId: "",
      CourseActive: true,
    };
  },
  components: {},
  created() {},
  mounted() {
    this.init();
    this.getQuestionBankQueryQuestionCourse();
  },
  watch: {
    $route: "init",
  },
  methods: {
    handleChange(obj) {
      // console.log(obj)
      // this.CourseId = value.split(',')[0];
      // this.CourseActive = value.split(',')[1];
      this.selectVal = obj.CourseName;
      this.CourseId = obj.CourseId;
      this.CourseActive = obj.IsActive;
      let path = "";
      if (obj.CourseName.includes("英语")) {
        this.practicesIndex = 5;
        path = "/personal/myPractice/afterPractice";
      } else {
        this.practicesIndex = 1;
        path = "/personal/myPractice/practiceNotes";
      }
      // console.log(this.practicesIndex, obj.CourseName.includes('英语'), '')
      this.$router.push({
        path: path,
        query: { CourseId: this.CourseId, CourseActive: this.CourseActive },
      });
    },
    //切换
    practicesClick(obj) {
      // console.log(obj)
      this.practicesIndex = obj.id;
      let path = "";
      if (this.practicesIndex == 1) {
        path = "/personal/myPractice/practiceNotes";
      } else if (this.practicesIndex == 2) {
        path = "/personal/myPractice/examinationRecord";
      } else if (this.practicesIndex == 3) {
        path = "/personal/myPractice/recordOfBrushingQuestions";
      } else if (this.practicesIndex == 4) {
        path = "/personal/myPractice/wrongQuestionRecord";
      } else if (this.practicesIndex == 5) {
        path = "/personal/myPractice/afterPractice";
      } else if (this.practicesIndex == 6) {
        path = "/personal/myPractice/newWordBook";
      } else if (this.practicesIndex == 7) {
        path = "/personal/myPractice/sentencePatternAnalysis";
      }
      this.$router.push({
        path: path,
        query: { CourseId: this.CourseId, CourseActive: this.CourseActive },
      });
    },
    init() {
      if (this.$route.path === "/personal/myPractice/practiceNotes") {
        this.practicesIndex = 1;
      } else if (
        this.$route.path === "/personal/myPractice/examinationRecord"
      ) {
        this.practicesIndex = 2;
      } else if (
        this.$route.path == "/personal/myPractice/recordOfBrushingQuestions"
      ) {
        this.practicesIndex = 3;
      } else if (
        this.$route.path == "/personal/myPractice/wrongQuestionRecord"
      ) {
        this.practicesIndex = 4;
      } else if (this.$route.path == "/personal/myPractice/afterPractice") {
        this.practicesIndex = 5;
      } else if (this.$route.path == "/personal/myPractice/newWordBook") {
        this.practicesIndex = 6;
      } else if (
        this.$route.path == "/personal/myPractice/sentencePatternAnalysis"
      ) {
        this.practicesIndex = 7;
      } else {
        this.practicesIndex = 0;
      }
    },
    async getQuestionBankQueryQuestionCourse() {
      const res = await questionBankQueryQuestionCourse();
      if (res.success === true) {
        this.list = res.response;
        this.CourseId = this.list[0].CourseId;
        this.selectVal = this.list[0].CourseName;
        this.CourseActive = this.list[0].IsActive;
        // this.$route.path
        if (this.selectVal.indexOf("英语") > -1) {
          this.$router.push({
            path: "/personal/myPractice/afterPractice",
            query: { CourseId: this.CourseId, CourseActive: this.CourseActive },
          });
        } else {
          this.$router.push({
            path: "/personal/myPractice/practiceNotes",
            query: { CourseId: this.CourseId, CourseActive: this.CourseActive },
          });
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.myPractice {
  .cont {
    font-size: 16px;
    padding: 10px 30px 30px;
  }
  .practice {
    cursor: pointer;
    // overflow: hidden;
    li {
      text-align: center;
      float: left;
      // padding: 25px 0 20px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      background-color: #f5f5f5;
      color: #666666;
      border-radius: 20px;
      margin-left: 20px;
      font-size: 13px;
      i {
        font-size: 16px;
        margin-right: 8px;
        // vertical-align: middle;
      }
      img {
        margin-right: 8px;
        vertical-align: text-bottom;
      }
      p {
        margin-top: 10px;
      }
    }
    .active {
      color: #1288f4;
      background-color: #e2ebfe;
    }
  }
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.courseList {
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
.courseList_hover {
  width: 217px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 10px;
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
}
.personal_title {
  height: auto;
  padding: 18px 30px;
  display: flex;
  justify-content: space-between;
}
</style>