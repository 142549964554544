/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:49:32 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-05 09:49:32 
 */
/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'

const http ={
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    get(url,params,loading = { showLoading: true }){
        const config = {
            method: 'get',
            url:url,
            loading:loading,
        }
        if(params) config.params = params
        return request(config)
    },
    post(url,params,loading = { showLoading: true },isContent=false){
        const config = {
            method: 'post',
            url:url,
            loading:loading,
            isContent:isContent
        }
        if(params) config.data = params
        return request(config)
    },
    put(url,params,loading = { showLoading: true }){
        const config = {
            method: 'put',
            url:url,
            loading:loading,
        }
        if(params) config.params = params
        return request(config)
    },
    delete(url,params,loading = { showLoading: true }){
        const config = {
            method: 'delete',
            url:url,
            loading:loading,
        }
        if(params) config.params = params
        return request(config)
    }
}
//导出
export default http