/* * @Author: liuzhixiang * @Date: 2021-02-06 15:17:54 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-03 19:49:05 */
<template>
  <div class="testPaper" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar title="练习" fixed left-arrow @click-left="onClickLeft" />
    </template>
    <div class="cont">
      <div class="top">
        <div class="title">{{ list.Name }}</div>
      </div>
      <div class="list">
        <div class="li" v-for="(li, i) in list.PaperItems" :key="i">
          <div class="title">{{ i + 1 }}、{{ li.Title }}</div>
          <div v-for="(lb, b) in li.PaperBankList" :key="b" class="paperBank">
            <div class="questionTitle">
              <p class="tmtype">
                <span>
                  {{
                    lb.QuestionCategory == 0
                      ? "单选"
                      : lb.QuestionCategory == 1
                      ? "多选"
                      : lb.QuestionCategory == 2
                      ? "判断"
                      : lb.QuestionCategory == 3
                      ? "填空"
                      : lb.QuestionCategory == 4
                      ? "简答"
                      : "论述"
                  }}
                </span>
                <span>题目{{ b + 1 }}：{{ lb.QuestionTitle }}</span>
              </p>
              <div class="correction" @click="correction(lb)">
                纠错 <i class="el-icon-edit-outline"></i>
              </div>
              <!-- <p class="tmmc">{{lb.QuestionTitle}}</p> -->
            </div>
            <el-radio-group
              v-if="lb.QuestionCategory === 0"
              v-model="lb.checkList"
              @change="handChange(lb)"
            >
              <p v-for="(lbb, p) in lb.BankItem" :key="p">
                <el-radio :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                >
              </p>
            </el-radio-group>
            <el-checkbox-group
              v-else-if="lb.QuestionCategory === 1"
              v-model="lb.checkList"
              @change="handChange(lb)"
            >
              <p v-for="(lbb, p) in lb.BankItem" :key="p">
                <el-checkbox :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
                >
              </p>
            </el-checkbox-group>
            <el-radio-group
              v-else-if="lb.QuestionCategory === 2"
              v-model="lb.checkList"
              @change="handChange(lb)"
            >
              <p v-for="(lbb, p) in lb.BankItem" :key="p">
                <el-radio :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                >
              </p>
            </el-radio-group>
            <el-input
              v-else-if="lb.QuestionCategory === 3"
              type="textarea"
              :rows="2"
              placeholder="请输入填空,多个填空以中文；隔开"
              @change="handChange(lb)"
              v-model="lb.checkList"
            >
            </el-input>
            <el-input
              v-else-if="lb.QuestionCategory === 4"
              type="textarea"
              :rows="2"
              placeholder="请输入简答"
              @change="handChange(lb)"
              v-model="lb.checkList"
            >
            </el-input>
            <el-input
              v-else-if="lb.QuestionCategory === 5"
              type="textarea"
              :rows="2"
              placeholder="请输入论述"
              @change="handChange(lb)"
              v-model="lb.checkList"
            >
            </el-input>
          </div>
        </div>
      </div>
      <!-- <div class="list" v-if="list.Name == undefined">
        <div class="li">暂无数据</div>
      </div> -->
      <div class="immediately" v-if="list.PaperItems">
        <el-button @click="buy()" class="buy">立即交卷</el-button>
      </div>
    </div>
    <!--纠错弹窗-->
    <dialog-Visible
      v-if="dialogVisible != false"
      type="1"
      :width="width"
      :dialogVisible="dialogVisible"
      :obj="correctionArr"
      @find="getFind"
      @close="getClose()"
    ></dialog-Visible>
  </div>
</template>

<script>
import {
  questionBankQueryPaperQuestionBank,
  questionBankSavePaperRecord,
  questionBankCorrectionQuestion,
} from "@/api/questionBank";
import dialogVisible from "@/components/components/dialogVisible.vue";
import common from "@/utils/common.js";
export default {
  data() {
    return {
      answer: {},
      list: [],
      obj: [],
      checkList: [],
      textarea: [],
      user: {},
      route: {},
      mobile: false,
      dialogVisible: false,
      width: "",
      correctionArr: {},
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "练习";
      this.isWxApplets = false;
    }
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.route = this.$route.query;
    let sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      //跳转移动端页面
      this.mobile = true;
    } else {
      //跳转pc端页面
      this.mobile = false;
    }
    this.init();
    common.timeCounting(true);
  },
  components: {
    dialogVisible,
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    async getFind(e) {
      console.log(e);
      const res = await questionBankCorrectionQuestion(e);
      if (res.success === true) {
        this.$message.success("提交成功");
        this.dialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 弹窗关闭
    getClose() {
      this.dialogVisible = false;
    },
    //纠错
    correction(data) {
      this.correctionArr = { Id: data.Id, Name: data.QuestionTitle };
      this.dialogVisible = true;
      if (this.mobile == true) {
        this.width = "85%";
      } else {
        this.width = "400px";
      }
    },
    //解析
    parsClick(obj) {
      obj.parsingShow = true;
      // console.log(obj, "");
    },
    //立即交卷
    async buy() {
      let parm = {
        paperId: this.list.Id,
        courseId: this.list.CourseId,
        studentId: this.route.UserID,
        Category: 0, //0试卷，1章节
        questionItems: this.obj,
      };
      let state = 0;
      if (this.list.PaperType != 2) {
        this.list.PaperItems.some((item) => {
          item.PaperBankList.some((it) => {
            if (it.checkList.length == 0 && it.checkList == "") {
              this.$message.info("未做题目：" + it.QuestionTitle);
              state = 1;
              return true;
            }
          });
        });
      } else {
        let count = 0,
          allQuestion = 0;
        this.list.PaperItems.some((item) => {
          item.PaperBankList.some((it) => {
            allQuestion++;
            if (it.checkList.length == 0 && it.checkList == "") {
              count++;
            }
          });
        });
        if (count == allQuestion) {
          this.$message.info("您还未完成任何题目，请作答后提交");
          return true;
        }
      }

      if (state != 1) {
        const res = await questionBankSavePaperRecord(parm);
        if (res.success === true) {
          this.$message.success(res.msg);
          let sUserAgent = navigator.userAgent.toLowerCase();
          let obj = {
            type: 1, //1套卷练习2章节练习
            useTime: common.timeCounting(false),
          };
          setTimeout(() => {
            if (
              /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
                sUserAgent
              )
            ) {
              //跳转移动端页面
              this.$router.push({
                path: "/H5/examinationResults",
                query: { ...res.response, ...obj },
              });
            } else {
              //跳转pc端页面
              this.$router.push({
                path: "/onlineQuestionBank/examinationResults",
                query: { ...res.response, ...obj },
              });
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    handChange(b) {
      // console.log(b, '')
      let arr = {};
      arr.questionId = b.Id;
      arr.questionCategory = b.QuestionCategory;
      arr.answer = b.checkList.toString();
      let stauts = 0;
      this.obj.forEach((item) => {
        if (item.questionId == arr.questionId) {
          item.answer = arr.answer;
          stauts = 1;
        }
      });
      if (stauts == 0) {
        this.obj.push(arr);
      }
      // console.log(this.obj, '')
    },
    init() {
      this.answer = this.$route.query;
      this.getquestionBankQueryPaperQuestionBank();
    },

    //题目列表
    async getquestionBankQueryPaperQuestionBank() {
      const res = await questionBankQueryPaperQuestionBank(this.answer.Id);
      if (res.success === true) {
        this.list = res.response;
        this.list.PaperItems.forEach((item) => {
          item.PaperBankList.forEach((it) => {
            if (
              it.QuestionCategory === 0 ||
              it.QuestionCategory === 1 ||
              it.QuestionCategory === 2
            ) {
              this.$set(it, "checkList", []);
            } else {
              this.$set(it, "checkList", "");
            }
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
  destroyed() {
    common.timeCounting(false);
  },
};
</script>

<style lang="less" scoped>
.testPaper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      .title {
        line-height: 100%;
        padding: 20px 0;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      .intr {
        padding: 0 10px 10px;
      }
    }
    .list {
      margin-bottom: 15px;
      .li {
        .js {
          font-size: 14px;
          line-height: 175%;
        }
        .title {
          padding: 10px 15px;
          background-color: #fff;
          margin-bottom: 10px;
          font-size: 15px;
        }
        .paperBank {
          margin-bottom: 5px;
          padding: 20px 30px;
          background-color: #fff;
          .questionTitle {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .correction {
              margin-left: 5px;
              color: #c5c5c5;
              font-size: 12px;
              width: 40px;
              cursor: pointer;
            }
            p {
              line-height: 22px;
              // height: 22px;
            }
            .tmtype {
              flex: 1;
              span {
                &:first-child {
                  display: inline-block;
                  background-color: #e2e2e2;
                  line-height: 22px;
                  height: 22px;
                  color: #fff;
                  padding: 0 8px;
                  border-radius: 20px;
                  margin-right: 10px;
                  font-size: 12px;
                }
              }
            }
            .tmmc {
              margin-top: 10px;
            }
          }
          .el-radio-group,
          .el-checkbox-group {
            .el-checkbox {
              // display: flex;
              // align-items: center;
            }
            .el-radio,
            .el-radio__input,
            .el-checkbox,
            .el-checkbox__input {
              white-space: normal !important;
              line-height: 175%;
            }
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .el-textarea {
            margin-bottom: 10px;
          }
        }
      }
    }
    .immediately {
      background-color: #fff;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
      position: sticky;
      bottom: 0px;
      left: 0;
      z-index: 0;
      .buy {
        background-color: #333;
        border: 1px solid #333;
        border-radius: 40px;
      }
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
