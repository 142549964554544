/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:42 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-09 10:34:32
 */

//  名词刷题 ： H5/topicPaper?Id=15&Name=2021《文艺理论》直播课&UserID=65
<template>
  <div class="Paper">
    <topic-paper></topic-paper>
  </div>
</template>

<script>
import topicPaper from "@/components/topicPaper.vue";
export default {
  data() {
    return {};
  },
  components: {
    topicPaper,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.Paper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>