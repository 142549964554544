/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:40 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-09 10:34:28
 */
//  套卷练习 ： H5/testPaper?Id=16&Name=2021《文艺理论》模拟试卷一&UserID=65
<template>
  <div class="Paper">
    <test-paper></test-paper>
  </div>
</template>

<script>
import testPaper from "@/components/testPaper.vue";
export default {
  data() {
    return {};
  },
  components: {
    testPaper,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.Paper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>