/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:48:38 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-05 09:49:04
 */
//消息提示过多，重置message，防止重复点击重复弹出message弹框，下一个弹框显示时关闭上一个弹框
import {
    Message
} from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
    if(messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message(options)
};
['error','success','info','warning'].forEach(type => {
    resetMessage[type] = options => {
        if(typeof options === 'string') {
            options = {
                message:options
            }
        }
        options.type = type
        return resetMessage(options)
    }
})
export const message = resetMessage;
