/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:49:51 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-Tu 09:28:04
 */
/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {
  Message
} from 'element-ui';

//消息提示过多，重置message，防止重复点击重复弹出message弹框，下一个弹框显示时关闭上一个弹框
import {
  message
} from '../utils/resetMessage.js'

import {
  showLoading,
  hideLoading
} from './loading.js'

import router from '../router/index'

//1. 创建新的axios实例，localStorage.getItem('token')
const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.BASE_API,
  // 超时时间 单位是ms，这里设置了Ns的超时时间
  timeout: 10 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  //  config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  const Authorization = localStorage.getItem('token');
  if (Authorization) {
    config.headers.Authorization = `Bearer ${Authorization}`
  };
  // console.log(config)
  if (config.isContent == true) {
    config.headers = {
      'Content-Type': 'application/json' //配置请求头
    }
  }

  //  //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  //  const token = "";//这里取token之前，你肯定需要先拿到token,存一下
  //  if(token){
  //     config.params = {'token':token} //如果要求携带在参数中
  //     config.headers.token= token; //如果要求携带在请求头中
  //   }

  //开启loading加载
  if (config.loading.showLoading === true) {
    if (config.url == '/api/api/v1/Student/CheckToken') {

    } else {
      showLoading()
    }

  }
  return config
}, error => {
  hideLoading()
  Promise.reject(error)
})
// 添加判断pc端移动端方法
function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

// 3.响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等

  //关闭loading加载
  hideLoading()
  return response
}, error => {
  /***** 接收到异常响应的处理开始 *****/
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '如果您更换了设备操作，请重新登录即可'
        if (isMobile()) {
          setTimeout(() => {
            window.location.href = 'https://yxyapp.micmark.com/app/pages/login/login'
          }, 3000)
        } else {
          setTimeout(() => {
            router.push({
              path: "/login"
            });
          }, 3000)
        }

        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        // window.location.href = "/NotFound"
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      message.error('网络不稳定，请刷新当前页')
    }
    // message.error('连接服务器失败')
  }
  if (error.message.includes('timeout')) {
    message.error('网络不稳定，请刷新当前页')
  } else {
    // message.error(error.message)
  }
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略

  //关闭loading加载
  hideLoading();
  return Promise.resolve(error.response)
})
//4. 导出
export default service