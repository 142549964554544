var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myPractice"},[_c('h3',{staticClass:"personal_title"},[_c('el-popover',{attrs:{"placement":"bottom","title":"","width":"200","trigger":"hover","content":""}},[_c('el-scrollbar',{staticStyle:{"height":"210px"}},_vm._l((_vm.list),function(item){return _c('div',{key:item.CourseId,staticClass:"courseList",on:{"click":function($event){return _vm.handleChange(item)}}},[_vm._v(" "+_vm._s(item.CourseName)+" ")])}),0),_c('div',{staticClass:"courseList_hover",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(_vm.selectVal))])],1),(_vm.selectVal.includes('英语'))?_c('ul',{staticClass:"practice"},_vm._l((_vm.practices),function(par,i){return (_vm.selectVal.includes('英语') && par.id > 4)?_c('li',{key:i,class:_vm.practicesIndex == par.id ? 'active' : '',on:{"click":function($event){return _vm.practicesClick(par)}}},[_c('div',[(par.icon)?_c('i',{class:_vm.practicesIndex == par.id
                ? par.icon + ' c_3A78F9'
                : par.icon + ' c_707070',attrs:{"color":"#E2EBFE"}}):_c('img',{attrs:{"src":require('@/assets/images/' +
                (_vm.practicesIndex == par.id ? par.img + '_checked' : par.img) +
                '.png'),"width":"14","alt":""}}),_c('span',{class:_vm.practicesIndex == par.id ? 'active' : ''},[_vm._v(" "+_vm._s(par.name)+" ")])])]):_vm._e()}),0):_vm._e(),(!_vm.selectVal.includes('英语'))?_c('ul',{staticClass:"practice"},_vm._l((_vm.practices),function(par,i){return (!_vm.selectVal.includes('英语') && par.id <= 4)?_c('li',{key:i,class:_vm.practicesIndex == par.id ? 'active' : '',on:{"click":function($event){return _vm.practicesClick(par)}}},[_c('div',[(par.icon)?_c('i',{class:_vm.practicesIndex == par.id
                ? par.icon + ' c_3A78F9'
                : par.icon + ' c_707070',attrs:{"color":"#E2EBFE"}}):_c('img',{attrs:{"src":require('@/assets/images/' +
                (_vm.practicesIndex == par.id ? par.img + '_checked' : par.img) +
                '.png'),"width":"14","alt":""}}),_c('span',{class:_vm.practicesIndex == par.id ? 'active' : ''},[_vm._v(" "+_vm._s(par.name)+" ")])])]):_vm._e()}),0):_vm._e()],1),(_vm.list.length > 0)?_c('div',[_c('div',{staticClass:"cont"},[_c('div',{staticClass:"he_10",staticStyle:{"margin":"0 -30px"}}),(_vm.list.length > 0)?_c('router-view'):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }