<template>
  <div>
    <van-tabbar v-model="active" active-color="#000" @change="handleChange">
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img slot="icon" :src="props.active ? icon.home2 : icon.home" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item>
        <span>分类</span>
        <template #icon="props">
          <img
            slot="icon"
            style="color: azure"
            :src="props.active ? icon.sel2 : icon.sel"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item>
        <span>购物车</span>
        <template #icon="props">
          <img slot="icon" :src="props.active ? icon.car2 : icon.car" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img slot="icon" :src="props.active ? icon.my2 : icon.my" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { queryStudentInfoById } from "@/api/bbs";
export default {
  data() {
    return {
      active: 0,
      icon: {
        home: require("../assets/ShopImg/nav/home1.png"),
        home2: require("../assets/ShopImg/nav/home2.png"),
        sel: require("../assets/ShopImg/nav/sel.png"),
        sel2: require("../assets/ShopImg/nav/sel2.png"),
        car: require("../assets/ShopImg/nav/car.png"),
        car2: require("../assets/ShopImg/nav/car2.png"),
        my: require("../assets/ShopImg/nav/my.png"),
        my2: require("../assets/ShopImg/nav/my2.png"),
      },
      user: {},
    };
  },
  created() {
    var that = this;
    that.init();
    // console.log(that.active, '')
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryUserInfo();
        that.handleChange();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted() {
    window.userIDResultByIos = this.userIDResultByIos;
  },
  methods: {
    userIDResultByIos(jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    // 用户详情
    async getQueryUserInfo() {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    handleChange() {
      if (this.active == 0) {
        // this.$router.push({
        //   path: "/shop/home",
        //   query: {},
        // });
        // window.location.href =
        //   "https://yxyapp.micmark.com/app/pages/shoppingMall/tabIndex";
        window.location.href =
          "https://live.pulijiaoyu.org.cn/app/pages/shoppingMall/tabIndex";
      } else if (this.active == 1) {
        this.$router.push({
          path: "/shop/translation",
          query: {},
        });
      } else if (this.active == 2) {
        this.$router.push({
          path: "/shop/ShopCar",
          query: {},
        });
        // this.gotoLogin("/shop/ShopCar");
      } else if (this.active == 3) {
        this.$router.push({
          path: "/shop/my",
          query: {},
        });
        //this.gotoLogin("/shop/my");
      }
    },
    //  判断是否登录
    gotoLogin(url) {
      if (localStorage.getItem("userInfo") != null) {
        this.user = JSON.parse(localStorage.getItem("userInfo"));
        this.$router.push({
          path: url,
          query: {},
        });
      } else {
        this.user.Id = 0;
        this.callApp();
      }
    },
    callApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(
          JSON.stringify({ action: "callIosGetUserID" })
        );
      }
    },
    init() {
      let route = this.$route.path.split("/")[2];
      if (route === "home") {
        this.active = 0;
      } else if (route === "translation") {
        this.active = 1;
      } else if (route === "ShopCar") {
        this.active = 2;
      } else if (route === "my") {
        this.active = 3;
      } else {
        this.active = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
