/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:33 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-31 10:43:42
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/Shop";
let resquest2 = "/api/api/v1";

// 商城轮播图
export async function queryBanners(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryBanners` + params)
  return res.data;
}

// 获取商品列表
export async function qeryShopGoodsList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryShopGoodsList`, params)
  return res.data;
}

// 获取商品详情
export async function queryShopGoodsDetail(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryShopGoodsDetail?id=` + params)
  return res.data;
}

// 获取商品分类
export async function queryGoodsCategoryTree(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryGoodsCategoryTree`)
  return res.data;
}

// 获取优惠券列表
export async function queryCouponList(params) {
  console.log(params)
  let res = await http.post(`${resquest}/ShopBase/QueryCouponList`, params)
  return res.data;
}

// 领取优惠券
export async function receiveShopCoupon(params) {
  console.log(params)
  let res = await http.post(`${resquest}/ShopBase/ReceiveShopCoupon`, params)
  return res.data;
}

// 获取我的优惠券
export async function queryMyCouponList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryMyCouponList`, params)
  return res.data;
}

// 获取商品评价列表
export async function queryGoodsEvaluation(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryGoodsEvaluation`, params)
  return res.data;
}

// 添加购物车
export async function shopCartAdd(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopCartAdd`, params)
  return res.data;
}


// 修改购物车数量
export async function shopCartEdit(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopCartEdit`, params)
  return res.data;
}

// 获取购物车列表
export async function queryShopCartList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryShopCartList`, params)
  return res.data;
}


// 删除购物车
export async function shopCartDelete(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopCartDelete`, params)
  return res.data;
}

// 商品结算
export async function shopSettlement(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopSettlement`, params)
  return res.data;
}

// 提交订单
export async function addShopOrder(params) {
  let res = await http.post(`${resquest}/ShopBase/AddShopOrder`, params)
  return res.data;
}

// 获取订单列表
export async function queryShopOrderList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryShopOrderList`, params)
  return res.data;
}

// 获取订单详情
export async function queryShopOrderDetail(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryShopOrderDetail?id=` + params)
  return res.data;
}

// 获取订单商品详情
export async function queryShopOrderGoodsDetail(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryShopOrderGoodsDetail?` + params)
  return res.data;
}

// 删除订单
export async function shopOrderDelete(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopOrderDelete?id=` + params)
  return res.data;
}

// 新增商品评价
export async function goodsEvaluationAdd(params) {
  let res = await http.post(`${resquest}/ShopBase/GoodsEvaluationAdd`, params)
  return res.data;
}

// 确认收货
export async function confirmReceipt(params) {
  let res = await http.get(`${resquest}/ShopBase/ConfirmReceipt?ligisticsId=` + params)
  return res.data;
}

// 获取订单物流信息
export async function queryShopLogisticsList(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryShopLogisticsList?orderId=` + params)
  return res.data;
}

// 获取评价商品列表
export async function queryEvaluationList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryEvaluationList`, params)
  return res.data;
}

// 获取收货地址列表
export async function queryUserAddress(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryUserAddress`, params)
  return res.data;
}
// 修改收货地址列表
export async function editUserAddress(params) {
  let res = await http.post(`${resquest}/ShopBase/UserAddressSave`, params)
  return res.data;
}
// 删除收货地址列表
export async function deleteUserAddress(params) {
  let res = await http.post(`${resquest}/ShopBase/UserAddressDelete?id=` + params)
  return res.data;
}

// 提交退费申请
export async function applyRefund(params) {
  let res = await http.post(`${resquest}/ShopBase/ApplyRefund`, params)
  return res.data;
}

// 根据Id获取学生信息
export async function queryStudentInfoById(params) {
  let res = await http.get(`${resquest}/Student/QueryStudentInfoById?id=` + params)
  return res.data;
}

// 获取积分列表
export async function queryPointList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryPointList`, params)
  return res.data;
}

// 获取首页轮播图
export async function queryPcbannerList(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryBanners` + params)
  return res.data;
}

// 在线客服
export async function queryOnlineService(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryOnlineService`, params)
  return res.data;
}

// 退款列表
export async function queryApplyRefundList(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryApplyRefundList`, params)
  return res.data;
}

// 获取退费详情
export async function queryApplyRefundDetail(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryApplyRefundDetail` + params)
  return res.data;
}

// 取消退款申请
export async function deleteShopRefund(params) {
  let res = await http.post(`${resquest}/ShopBase/DeleteShopRefund?id=` + params)
  return res.data;
}

// 待支付订单发起支付
export async function shopOrderPay(params) {
  let res = await http.post(`${resquest}/ShopBase/ShopOrderPay`, params)
  return res.data;
}

// 帮助中心
export async function queryHelpCenter(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryHelpCenter`, params)
  return res.data;
}

//用户反馈
export async function querFeedback(params) {
  let res = await http.post(`${resquest2}/Base/FeedbackAdd`, params)
  return res.data;
}

//
export async function queryGoodsPropertyTree(params) {
  let res = await http.get(`${resquest}/ShopBase/QueryGoodsPropertyTree` + params)
  return res.data;
}

//PC底部公司介绍公司地址
export async function queryCompanyIntroduce(params) {
  let res = await http.get(`${resquest2}/Base/QueryCompanyIntroduce`, params)
  return res.data;
}

// 商品列表
export async function queryShopGoodsListByApp(params) {
  let res = await http.post(`${resquest}/ShopBase/QueryShopGoodsListByApp`, params);
  return res.data;
}