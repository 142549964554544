/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:20:15 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 15:20:15 
 */
<template>
  <div class="register">
    <div class="cont">
      <h3>忘记密码</h3>
      <div class="zc">
        <p class="tli">手机号码</p>
        <div class="re_row">
          <el-input
            placeholder="请输入11位手机号码"
            type="tel"
            v-model="phone"
          ></el-input>
        </div>
        <p class="tli">验证码</p>
        <div class="re_row">
          <el-input
            placeholder="请输入验证码"
            type="number"
            v-model="code"
          ></el-input>
          <div class="re_code">
            <span v-if="showTime" @click="getCode()">获取验证码</span>
            <span v-else>{{times}} s</span>
          </div>
        </div>
        <p class="tli">输入密码</p>
        <div class="re_row">
          <el-input
            placeholder="请输入6位及以上字符"
            type="password"
            v-model="pass"
          ></el-input>
        </div>
        <p class="tli">再次输入密码</p>
        <div class="re_row">
          <el-input
            placeholder="请输入6位及以上字符"
            type="password"
            v-model="pass2"
          ></el-input>
        </div>
        <div class="re_row">
          <el-checkbox v-model="checked">同意阅读服务条款</el-checkbox>
        </div>
        <el-button type="primary" @click="save()">修改密码</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { commonSendCodeMsg } from "@/api/api";
import { studentStudentEditPassword } from "@/api/login";
export default {
  data() {
    return {
      phone: "",
      pass: "",
      pass2: "",
      code: "",
      thCode:"",
      checked: false,
      times: 60,
      showTime:true,
      timer:"",
    };
  },
  created() {},
  mounted() {
    // this.postCommonSendCodeMsg();
  },
  methods: {
    //立即注册
    async save(){
      if (this.phone == "" || this.phone.length < 11) {
        this.$message.info("请输入正确的手机号码");
        return false;
      }
      if (this.code == "" || Object.is(this.code,this.thCode) == false) {
        this.$message.info("请输入正确的验证码");
        return false;
      }
      if (this.pass == "" || Object.is(this.pass,this.pass2) == false) {
        this.$message.info("两次输入密码不一致");
        return false;
      }
      if (this.checked == false) {
        this.$message.info("请同意阅读服务条款");
        return false;
      }
      let parm = {
        pwd:this.pass,
        userName:this.phone,
      }
      const res = await studentStudentEditPassword(parm);
      if (res.success == true) {
        this.$message.success(res.msg+",两秒后重新登陆");
        setTimeout(()=>{
          this.$router.push({ path: "/login" });
        }, 2000);
      }else{
        this.$message.error(res.msg);
      }

    },
    //获取验证码
    getCode() {
      if (this.phone == "" || this.phone.length < 11) {
        this.$message.info("请输入正确的手机号码");
        return false;
      }
      this.postCommonSendCodeMsg();
    },
    async postCommonSendCodeMsg(){
      const res = await commonSendCodeMsg(this.phone);
      if (res.success == true) {
        this.thCode = res.response;
        this.$message.success("短信发送成功");
        this.showTime = false;
        this.timer = setInterval(()=>{
          this.times--;
          if(this.times===0){
            this.showTime = true;
            clearInterval(this.timer)
          }
        },1000)
      }else{
        this.$message.success(res.msg);
      }
    }
  },
  destroyed() {
     clearInterval(this.timer); //关闭
  },
};
</script>

<style lang="less" scoped>
.register {
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  .cont {
    width: 800px;
    min-height: 550px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 10px;
    h3 {
      color: #ffffff;
      font-size: 28px;
      font-weight: normal;
      height: 64px;
      line-height: 64px;
      text-align: center;
      background: linear-gradient(90deg, #2f70e4 0%, #48a8f4 100%);
      border-radius: 10px 10px 0px 0px;
    }
    .zc {
      width: 440px;
      margin: 16px auto 0;
      .tli {
        line-height: 57px;
        font-size: 18px;
      }
      .re_row {
        display: flex;
        justify-content: space-between;
        .el-input {
          flex: 1;
          font-size: 18px;
          /deep/ input {
            height: 60px;
            padding-left: 26px;
          }
        }
        .re_code {
          width: 130px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          cursor: pointer;
          background: #1288f4;
          border-radius: 0px 6px 6px 0px;
          font-size: 18px;
          font-weight: 300;
          color: #ffffff;
          span{
            display: block;
          }
        }
      }
      .el-checkbox {
        font-size: 18px;
        color: #666666;
        line-height: 100%;
        margin: 20px 0 20px;
      }
      .el-button {
        width: 100%;
        height: 70px;
        font-size: 24px;
        background-color: #1288f4;
      }
    }
  }
}
</style>