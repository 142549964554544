/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:33 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-24 16:43:59
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";

// 获取试卷列表
export async function questionBankQueryPaperList(params) {
  let res = await http.post(`${resquest}/QuestionBank/QueryPaperList`, params)
  return res.data;
}

// 试卷详情并判断学生是否有该试卷
export async function questionBankQueryPaperDetail(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryPaperDetail?Id=` + params)
  return res.data;
}

// 试卷进入答题
export async function questionBankQueryPaperQuestionBank(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryPaperQuestionBank?Id=` + params)
  return res.data;
}

// 名词刷题
export async function questionBankQueryWordsList(params) {
  let res = await http.post(`${resquest}/QuestionBank/QueryWordsList`, params)
  return res.data;
}

// 名词刷题提交
export async function questionBankSaveWordsRecord(params) {
  let res = await http.post(`${resquest}/QuestionBank/SaveWordsRecord`, params)
  return res.data;
}

// 章节练习
export async function questionBankQueryChapters(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryChapters` + params)
  return res.data;
}

// 获取章节题目
export async function questionBankQueryChapterQuestion(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryChapterQuestion` + params)
  return res.data;
}

// 试卷提交
export async function questionBankSavePaperRecord(params) {
  let res = await http.post(`${resquest}/QuestionBank/SavePaperRecord`, params)
  return res.data;
}

// 题目纠错
export async function questionBankCorrectionQuestion(params) {
  let res = await http.post(`${resquest}/QuestionBank/CorrectionQuestion`, params)
  return res.data;
}

// 章节练习记录详情
export async function questionBankQueryChapterRecordDetail(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryChapterRecordDetail?recordId=` + params)
  return res.data;
}

// 试卷考试记录详情
export async function questionBankQueryPaperQuestionBankByRecord(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryPaperQuestionBankByRecord?recordId=` + params)
  return res.data;
}

// 设置学习目标
export async function setTaskSetting(params) {
  let res = await http.post(`${resquest}/EnglishPractice/SetTaskSetting`, params)
  return res.data;
}

// 查询学习信息 进入页面时获取
export async function queryStudySituation(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryStudySituation` + params)
  return res.data;
}

//获取单词分类 首页
export async function queryEnglishCategory(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryEnglishCategory` + params)
  return res.data;
}

// 每日任务生成 —— 进入学习时调用
export async function setWordsTask(params) {
  let res = await http.post(`${resquest}/EnglishPractice/SetWordsTask`, params)
  return res.data;
}

// 查询学生的每日单词
export async function queryWordsTask(params) {
  let res = await http.post(`${resquest}/EnglishPractice/QueryWordsTask`, params)
  return res.data;
}

// 打卡日历
export async function queryStudentTask(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryStudentTask` + params)
  return res.data;
}

// 获取未认识的题目
export async function queryNoMasterWords(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryStudentNoMasterWords` + params)
  return res.data;
}

// 保存答题
export async function taskWordsSave(params) {
  let res = await http.post(`${resquest}/EnglishPractice/TaskWordsSave`, params)
  return res.data;
}

// 保存答题
export async function resetEnglishPractice(params) {
  let res = await http.post(`${resquest}/EnglishPractice/ResetEnglishPractice`, params)
  return res.data;
}

// 课后练习
export async function queryPracticeInfo(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryPracticeInfo` + params)
  return res.data;
}

// 句式分析分类
export async function querySentenceCategory(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QuerySentenceCategory` + params)
  return res.data;
}

// 词汇与语法
export async function queryGrammar(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryGrammar` + params)
  return res.data;
}

// 句式分析分类
export async function qrammarSave(params) {
  let res = await http.post(`${resquest}/EnglishPractice/GrammarSave`, params)
  return res.data;
}

// 获取翻译、句式分析、写作题
export async function queryEnglishQuestion(params) {
  let res = await http.post(`${resquest}/EnglishPractice/QueryEnglishQuestion`, params)
  return res.data;
}

// 获取翻译、句式分析、写作题
export async function englishPracticeSave(params) {
  let res = await http.post(`${resquest}/EnglishPractice/EnglishPracticeSave`, params)
  return res.data;
}

// 英语单词按分类查询
export async function queryEnglishWord(params) {
  let res = await http.post(`${resquest}/EnglishPractice/QueryEnglishWord`, params)
  return res.data;
}

// 英语单词保存
export async function saveWordsRecord(params) {
  let res = await http.post(`${resquest}/EnglishPractice/SaveWordsRecord`, params)
  return res.data;
}

// 句式分析、翻译、写作记录
export async function queryPracticeReocrd(params) {
  let res = await http.post(`${resquest}/EnglishPractice/QueryPracticeReocrd`, params)
  return res.data;
}

// 生词本
export async function queryWordsBook(params) {
  let res = await http.post(`${resquest}/EnglishPractice/QueryWordsBook`, params)
  return res.data;
}

// 词汇与语法记录
export async function queryGrammarRecord(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryGrammarRecord` + params)
  return res.data;
}

// 生词本详情
export async function queryWordsById(params) {
  let res = await http.get(`${resquest}/EnglishPractice/QueryWordsById` + params)
  return res.data;
}