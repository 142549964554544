/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:07 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-11-Tu 05:53:40
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";
let resquestImg = "/api";
let resquestOCR = "/api/api";

// 发送短信
export async function commonSendCodeMsg(params) {
  let res = await http.post(`${resquest}/Common/SendCodeMsg?Phone=` + params)
  return res.data;
}

// 图片上传
export async function imagesUploadPic(params) {
  let res = await http.post(`${resquestImg}/images/Upload/Pic`, params)
  return res.data;
}

// 身份证识别
export async function OCRIdCardOcr(params) {
  let res = await http.post(`${resquestOCR}/OCR/IdCardOcr`, params)
  return res.data;
}

// 人脸识别
export async function studentCompareFace(params) {
  let res = await http.post(`${resquest}/Student/CompareFace`, params)
  return res.data;
}

// 人脸识别规则
export async function sysConfigQueryFaceSetting(params) {
  let res = await http.get(`${resquest}/SysConfig/QueryFaceSetting?category=` + params)
  return res.data;
}

// 系统配置
export async function querySystemConfig(params) {
  let res = await http.get(`${resquest}/SysConfig/QuerySystemConfig?code=` + params)
  return res.data;
}
// Token验证
export function CheckToken(params) {
  return http.get(`${resquest}/Student/CheckToken`, params)
}

//请求模板
// get请求
export function getListAPI(params) {
  return http.get(`${resquest}/getList.json`, params)
}
// post请求
export function postFormAPI(params) {
  return http.post(`${resquest}/postForm.json`, params)
}
// put 请求
export function putSomeAPI(params) {
  return http.put(`${resquest}/putSome.json`, params)
}
// delete 请求
export function deleteListAPI(params) {
  return http.delete(`${resquest}/deleteList.json`, params)
}


/**
    //promise调用，链式调用， getList()括号内只接受参数；
    //get不传参
    getList() {
    getListAPI().then(res => console.log(res)).catch(err => console.log(err))
    },
    //post传参
    postForm(formData) {
    let data = formData
    postFormAPI(data).then(res => console.log(res)).catch(err => console.log(err))
    },
    //async await同步调用
    async postForm(formData) {
    const postRes = await postFormAPI(formData)
    const putRes = await putSomeAPI({data: 'putTest'})
    const deleteRes = await deleteListAPI(formData.name)
    // 数据处理
    console.log(postRes);
    console.log(putRes);
    console.log(deleteRes);
    },
*/