/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:17:23 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-08 14:51:26
 */
<template>
  <div class="noData">
    <!-- <img src="../assets/images/noData.png" />
    <p>暂无数据</p> -->
    <img src="../assets/images/noData1.png" />
    <p>暂无内容</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
        
    };
  },
  components: {
  },
  created() {},
  mounted() {
      
  },
  methods: {
      
  },
  destroyed() {
  }
};
</script>

<style lang="less" scoped>
.noData {
    text-align:center;
    padding-top: 100px;
    color: #8a8a8a;
    img{
        width: 75px;
    }
    p{
        color: #6B666B;
        margin-top: 10px;
    }
}
</style>