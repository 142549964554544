<template>
  <el-dialog
    title="人脸识别"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div class="camera_outer">
      <video
        id="videoCamera"
        :width="videoWidth"
        :height="videoHeight"
        autoplay
      ></video>
      <canvas
        style="display: none"
        id="canvasCamera"
        :width="videoWidth"
        :height="videoHeight"
      ></canvas>
      <div v-if="imgSrc" class="img_bg_camera">
        <!-- <p>效果预览</p> -->
        <img v-lazy="imgSrc" alt class="tx_img" />
      </div>
      <!-- <div class="button">
        <el-button @click="getCompetence()">打开摄像头</el-button>
        <el-button @click="stopNavigator()">关闭摄像头</el-button>
        <el-button @click="setImage()">拍照</el-button>
      </div>-->
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="handleClose()">取 消</el-button> -->
      <el-button type="primary" @click="photograph()">拍 照</el-button>
    </span>
  </el-dialog>
</template>
  <script>
import { getAccessToken, faceMatch } from "@/api/home";
export default {
  props: ["dialogVisible","isFile"],
  data() {
    return {
      videoWidth: 350,
      videoHeight: 350,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      camera: true,
      access_token: "",
      user: {},
      isPass:false
    };
  },
  mounted() {
    setTimeout(() => {
      this.getCompetence(); //进入页面就调用摄像头
    }, 100);
    // this.getCompetence();//进入页面就调用摄像头
    this.getToken();
    this.user = JSON.parse(this.$store.state.userInfo);
     if(!localStorage.getItem("faceImgBase64"))
     {
       this.imageUrlToBase64(this.GLOBAL.hostUrl +this.user.FaceImg)
     }
  },
  watch: {
    dialogVisible: "dialogVisibleShow",
    isFile:"isFile",
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    //拍照
    photograph() {
      this.setImage();
    },
    dialogVisibleShow() {
      if (this.dialogVisible == false) {
        this.stopNavigator(); //关闭摄像头
      } else {
        this.imgSrc = "";
        setTimeout(() => {
          this.getCompetence(); //进入页面就调用摄像头
        }, 100);
      }
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      var _this = this;
      _this.thisCancas = document.getElementById("canvasCamera");
      _this.thisContext = this.thisCancas.getContext("2d");
      _this.thisVideo = document.getElementById("videoCamera");
      // _this.videoWidth = document.getElementsByClassName(
      //   "camera_outer"
      // ).clientWidth;
      _this.thisVideo.style.display = "block";
      _this.thisVideo.style.margin = "0 auto";
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //  绘制图片（拍照功能）
    setImage() {
      var _this = this;
      // 无摄像头时
      if (_this.thisVideo == null) {
        this.$message.error("摄像头出错");
        this.$emit("close");
        return false;
      }
      // canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL("image/png");
       _this.imgSrc = image; //赋值并预览图片
      if(this.isFile == true)
      {
         var file = this.dataURLtoFile(_this.imgSrc, "imgSrc.png");
         this.$emit("studentface", file);
      }
      else
      {
         this.postFaceMatch(image.split(",")[1],  localStorage.getItem("faceImgBase64").split(",")[1]);
          console.log(this.isPass);
      }
     
      this.stopNavigator(); //关闭摄像头
    },
    // 关闭摄像头
    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop();
    },
    // base64转文件，此处没用到
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async getToken() {
      const res = await getAccessToken({});
      if (res.success == true) {
        this.access_token = JSON.parse(res.response).access_token;
      } else {
        this.access_token = "";
      }
    },
    async postFaceMatch(img1, img2) {
      // console.log(img1);
      // console.log(img2);
      let parm = [];
      parm.push({
        image: img1,
        image_type: "BASE64",
        face_type: "LIVE",
        quality_control: "LOW",
        liveness_control: "LOW",
      });
      parm.push({
        image: img2,
        image_type: "BASE64",
        face_type: "LIVE",
        quality_control: "LOW",
        liveness_control: "LOW",
      });
      console.log(parm);
      const res = await faceMatch(this.access_token,parm);
      console.log(res);
      if(res.result!=null)
      {
        console.log(parseFloat(this.user.Score))
        console.log(parseFloat(res.result.score))
        this.isPass = parseFloat(this.user.Score)  < parseFloat(res.result.score);
      }
      else
      {
        this.isPass = false;
      }
      this.$emit("studentface", this.isPass);
    },
    		//异步执行
		imageUrlToBase64(imageUrl) {
			//一定要设置为let，不然图片不显示
			let image = new Image();
	
			//解决跨域问题
			image.setAttribute('crossOrigin', 'anonymous');
			
			// let imageUrl = "http://bigf.cqugeo.cn/landslip/res/defaultImages/default.png";
			image.src = imageUrl+ "?" + Math.random()
			
			//image.onload为异步加载
			image.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, image.width, image.height);
				
				var quality = 0.8;
				//这里的dataurl就是base64类型
				let faceImg = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        localStorage.setItem("faceImgBase64",faceImg)
			}
		},
  },
};
</script>
  <style lang="less" scoped>
.camera_outer {
  width: 350px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  //   background: url("../../assets/img/user_0608_04.png") no-repeat center;
  background-size: 100%;
  video,
  canvas,
  .tx_img {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .btn_camera {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 50px;
    text-align: center;
    color: #ffffff;
  }
  .bg_r_img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .img_bg_camera {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
    }
    .img_btn_camera {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.3);
      color: #ffffff;
      .loding_img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>