/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:57:29 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-We 05:09:26
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1"

let loading = {
  showLoading: false
}


// 获取学生点播课程
export async function courseQueryVideoCourse(params) {
  let res = await http.post(`${resquest}/Course/QueryVideoCourse`, params);
  return res.data;
}

// 获取点播视频列表
export async function courseQueryVideoList(params) {
  let res = await http.post(`${resquest}/Course/QueryVideoList?courseId=` + params);
  return res.data;
}

// 点播解密
export async function liveQueryVideoToken(params) {
  let res = await http.get(`${resquest}/Live/QueryVideoToken?videoId=` + params);
  return res.data;
}

// 点播进度 每隔10s调用移除
export async function videoUpdateVideoProgress(params) {
  let res = await http.post(`${resquest}/Video/UpdateVideoProgress`, params, loading);
  return res.data;
}

// 重置播放进度
export async function videoResetVideoProgress(params) {
  let res = await http.post(`${resquest}/Video/ResetVideoProgress`, params);
  return res.data;
}

// PC端观看直播参数获取
export async function liveQueryLiveToken(params) {
  let res = await http.post(`${resquest}/Live/QueryLiveToken?channelId=` + params);
  return res.data;
}

// 获取学生用户信息
export async function commonQueryUserInfo(params) {
  let res = await http.post(`${resquest}/Common/QueryUserInfo`, params);
  return res.data;
}

// 获取考试方向
export async function baseQueryExamDirection(params) {
  let res = await http.post(`${resquest}/Base/QueryExamDirection`, params);
  return res.data;
}

// 获取学校
export async function baseQuerySchool(params) {
  let res = await http.post(`${resquest}/Base/QuerySchool`, params);
  return res.data;
}

// 获取专业
export async function baseQueryMajor(params) {
  let res = await http.post(`${resquest}/Base/QueryMajor`, params);
  return res.data;
}

// 培训课程
export async function baseQueryTrainCourse(params) {
  let res = await http.post(`${resquest}/Base/QueryTrainCourse`, params);
  return res.data;
}

// 完善信息
export async function studentReplenish(params) {
  let res = await http.post(`${resquest}/Student/Replenish`, params);
  return res.data;
}

// 修改信息
export async function studentStudentInfoEdit(params) {
  let res = await http.post(`${resquest}/Student/StudentInfoEdit`, params);
  return res.data;
}

// 获取学生课程
export async function courseQueryUserCourse(params) {
  let res = await http.post(`${resquest}/Course/QueryUserCourse`, params);
  return res.data;
}

// 获取学员所拥有的资料列表
export async function studentQueryStudentFiles(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentFiles`, params);
  return res.data;
}

// 获取课程作业列表
export async function studentQueryCourseWorkList(params) {
  let res = await http.post(`${resquest}/Student/QueryCourseWorkList`, params);
  return res.data;
}

// 提交作业
export async function studentSaveStudentCourseWork(params) {
  let res = await http.post(`${resquest}/Student/SaveStudentCourseWork`, params);
  return res.data;
}

// 获取学员作业作答详情
export async function studentQueryStudentWorkDetail(params) {
  let res = await http.get(`${resquest}/Student/QueryStudentWorkDetail?workId=` + params);
  return res.data;
}

// 获取普利点使用记录
export async function studentQueryStudentECashRecordList(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentECashRecordList`, params);
  return res.data;
}

// 获取学员排课信息
export async function studentQueryStudentCoursePlanList(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentCoursePlanList`, params);
  return res.data;
}

// 获取学员优惠券信息
export async function studentQueryStudentCouponList(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentCouponList`, params);
  return res.data;
}

// 个人中心获取题目课程
export async function questionBankQueryQuestionCourse(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryQuestionCourse`, params);
  return res.data;
}

// 个人中心名词刷题记录
export async function questionBankQueryUserWordsRecord(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryUserWordsRecord?courseId=` + params);
  return res.data;
}

// 个人中心获取练习记录
export async function questionBankQueryUserChapterRecord(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryUserChapterRecord` + params);
  return res.data;
}

// 个人中心获取考试记录
export async function questionBankQueryUserPaperRecord(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryUserPaperRecord` + params);
  return res.data;
}

// 获取学生错题列表
export async function questionBankQueryStudentWrongRecordList(params) {
  let res = await http.post(`${resquest}/QuestionBank/QueryStudentWrongRecordList`, params);
  return res.data;
}

// 个人中心获取错题记录
export async function questionBankQueryWrongRecord(params) {
  let res = await http.get(`${resquest}/QuestionBank/QueryWrongRecord` + params);
  return res.data;
}

// 刷题记录获取详情列表
export async function questionBankQueryUserWordsByAnswers(params) {
  let res = await http.post(`${resquest}/QuestionBank/QueryUserWordsByAnswers`, params);
  return res.data;
}

// 获取课程列表
export async function QueryStudentCoursePlanList(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentCoursePlanList`, params);
  return res.data;
}

// 学生打卡
export async function studentSign(params) {
  let res = await http.post(`${resquest}/Student/StudentSign`, params);
  return res.data;
}

// 学生打卡
export async function studentSignByTable(params) {
  let res = await http.post(`${resquest}/Student/StudentSignByTable`, params);
  return res.data;
}

// 签到记录
export async function studentQueryStudentCoursePlanSignList(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentCoursePlanSignList`, params);
  return res.data;
}

// 签到详情
export async function studentQueryStudentSignDetail(params) {
  let res = await http.post(`${resquest}/Student/QueryStudentSignDetail`, params);
  return res.data;
}

// 获取当月是否有课程
export async function QueryCoursePlanCount(params) {
  let res = await http.post(`${resquest}/Student/QueryCoursePlanCount`, params);
  return res.data;
}

// 新增课程评论
export async function addCourseComment(params) {
  let res = await http.post(`${resquest}/Course/AddCourseComment`, params);
  return res.data;
}

// 个人信息
export async function queryStudentInfoById(params) {
  let res = await http.get(`${resquest}/Student/QueryStudentInfoById` + params);
  return res.data;
}

// 通知列表
export async function queryNotice(params) {
  let res = await http.get(`${resquest}/Student/QueryNotice` + params);
  return res.data;
}

// 通知已读
export async function readNotice(params) {
  let res = await http.post(`${resquest}/Student/ReadNotice` + params);
  return res.data;
}

// 判断课程或视频是否有效
export async function checkValid(params) {
  let res = await http.post(`${resquest}/Video/CheckValid` , params);
  return res.data;
}