/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 18:12:06 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-10 10:17:16
 */
<template>
  <!-- :class="NavShow ? 'shop' : 'shopFirst'" -->
  <div>
    <!-- 这里是不被缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive" />
    <div style="height: 50px"></div>
    <Nav v-if="NavShow"></Nav>
  </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav,
  },
  data () {
    return {
      NavShow: false,
    };
  },
  created () { },
  watch: {
    $route: "init",
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      if (
        this.$route.path === "/shop/home" ||
        this.$route.path === "/shop/translation" ||
        this.$route.path === "/shop/ShopCar" ||
        this.$route.path === "/shop/my"
      ) {
        this.NavShow = true;
      } else {
        this.NavShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shop {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
.shopFirst {
  height: 100vh;
  overflow-y: scroll;
}
</style>
