/*
 * @Author: liuzhixiang
 * @Date: 2021-02-05 09:50:06
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-We 04:13:17
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";
import Login from "../views/login/index.vue";
import Personal from "../views/personal/index.vue";
import Course from "../views/course/index.vue";
import DataDownload from "../views/dataDownload/index.vue";
import OnlineQuestionBank from "../views/onlineQuestionBank/index.vue";
import ForgetPassword from "../views/login/forgetPassword.vue";
import Register from "../views/login/register.vue";
import TestPaper from "../views/H5/testPaper.vue";
import TopicPaper from "../views/H5/topicPaper.vue";
import ExaminationResults from "../views/H5/examinationResults.vue";
import ChapterExercises from "../views/H5/chapterExercises.vue";
import Shop from "../views/shop/index.vue";

import MyPractice from "../views/personal/myPractice/practice.vue";

Vue.use(VueRouter);

// 重复路由跳转
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/",
    name: "home",
    component: Index,
    redirect: "/home", //默认显示
    meta: {
      title: "首页",
    },
    children: [{
        path: "/home",
        name: "home",
        component: () => import("../views/home/index1.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/home/teacherDetails",
        name: "home/teacherDetails",
        component: () => import("../views/home/teacherDetails.vue"),
        meta: {
          title: "教师详情",
        },
      },
      {
        path: "/dev",
        name: "dev",
        component: () => import("../views/dev.vue"),
        meta: {
          title: "开发中",
        },
      },
      {
        path: "/art",
        name: "art",
        redirect: "/art/index", //默认显示
        component: () => import("../views/art/index.vue"),
        meta: {
          title: "在线艺术",
        },
        children: [{
            path: "/art/index",
            name: "art/index",
            component: () => import("../views/art/artExhibition1.vue"),
            meta: {
              title: "在线艺术",
            },
          }, {
            path: "/art/list",
            name: "art/list",
            component: () => import("../views/art/list.vue"),
            meta: {
              title: "在线艺术列表",
            },
          },
          {
            path: "/art/artExhibitionDetail",
            name: "art/artExhibitionDetail",
            component: () => import("../views/art/artExhibitionDetail.vue"),
            meta: {
              title: "详情",
            },
          },
          {
            path: "/art/artists",
            name: "art/artists",
            component: () => import("../views/art/artists.vue"),
            meta: {
              title: "艺术家列表",
            },
          },
          {
            path: "/art/artistsDetails",
            name: "art/artistsDetails",
            component: () => import("../views/art/artistsDetails.vue"),
            meta: {
              title: "作品详情",
            },
          },
          {
            path: "/art/artistRegister",
            name: "art/artistRegister",
            component: () => import("../views/art/artistRegister.vue"),
            meta: {
              title: "艺术家入驻",
            },
          },
          {
            path: "/art/artworkAdd",
            name: "art/artworkAdd",
            component: () => import("../views/art/artworkAdd.vue"),
            meta: {
              title: "作品发布",
            },
          },
          {
            path: "/art/artistIntroduction",
            name: "art/artistIntroduction",
            component: () => import("../views/art/artistIntroduction.vue"),
            meta: {
              title: "艺术家",
            },
          }
        ],
      },
      {
        path: "/fineArts",
        name: "fineArts",
        redirect: "/fineArts/index", //默认显示
        component: () => import("../views/fineArts/index.vue"),
        meta: {
          title: "三合美术馆",
        },
        children: [{
            path: "/fineArts/index",
            name: "fineArts/index",
            component: () => import("../views/fineArts/fineArts.vue"),
            meta: {
              title: "三合美术馆",
            },
          },
          {
            path: "/fineArts/details",
            name: "fineArts/details",
            component: () => import("../views/fineArts/details.vue"),
            meta: {
              title: "三合美术馆详情",
            },
          },
          {
            path: "/fineArts/produceDetail",
            name: "fineArts/produceDetail",
            component: () => import("../views/fineArts/produceDetail.vue"),
            meta: {
              title: "作品详情",
            },
          }
        ],
      },
      {
        path: "/teacher/index",
        name: "teacher/index",
        redirect: "/teacher/index", //默认显示
        component: () => import("../views/teacher/index.vue"),
        meta: {
          title: "资深讲师",
        },
        children: [{
            path: "/teacher/index",
            name: "teacher/index",
            component: () => import("../views/teacher/teachers.vue"),
            meta: {
              title: "资深讲师",
            },
          },
          {
            path: "/teacher/teacherDetails",
            name: "teacher/teacherDetails",
            component: () => import("../views/teacher/teacherDetails.vue"),
            meta: {
              title: "讲师详情",
            },
          },
          {
            path: "/teacher/teacherWorksDetails",
            name: "teacher/teacherWorksDetails",
            component: () => import("../views/teacher/teacherWorksDetails.vue"),
            meta: {
              title: "作品详情",
            },
          },
        ],
      },
      {
        path: "/informationList/index",
        name: "informationList/index",
        redirect: "/informationList/index", //默认显示
        component: () => import("../views/informationList/index.vue"),
        meta: {
          title: " 艺术资讯",
        },
        children: [{
            path: "/informationList/index",
            name: "informationList/index",
            component: () => import("../views/informationList/news.vue"),
            meta: {
              title: "艺术资讯",
            },
          },
          {
            path: "/informationList/details",
            name: "informationList/details",
            component: () => import("../views/informationList/details.vue"),
            meta: {
              title: "资讯详情",
            },
          }
        ],
      },
      {
        path: "/bbs",
        name: "bbs",
        //component: Bbs,
        redirect: "/bbs/Index", //默认显示
        component: () => import("../views/bbs/Index.vue"),
        meta: {
          title: "艺术交流",
        },
        children: [{
            path: "/bbs/Index",
            name: "bbs/Index",
            component: () => import("../views/bbs/Index1.vue"),
            meta: {
              title: "艺术交流",
            },
          },
          {
            path: "/bbs/detail",
            name: "bbs/detail",
            component: () => import("../views/bbs/detail.vue"),
            meta: {
              title: "详情",
            },
          },
          {
            path: "/bbs/myTopic",
            name: "bbs/myTopic",
            component: () => import("../views/bbs/myTopic.vue"),
            meta: {
              title: "我的发帖",
            },
          },
        ],
      },
      {
        path: "/course/index",
        name: "course/index",
        component: Course,
        redirect: "/course/index", //默认显示
        meta: {
          title: "精选课程",
        },
        children: [{
            path: "/course/index",
            name: "course/index",
            component: () => import("../views/course/course.vue"),
            meta: {
              title: "精选课程",
            },
          },
          {
            path: "/course/details",
            name: "course/details",
            component: () => import("../views/course/details.vue"),
            meta: {
              title: "课程详情",
            },
          },
          {
            path: "/course/order",
            name: "course/order",
            component: () => import("../views/course/order.vue"),
            meta: {
              title: "订单结算",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/dataDownload",
        name: "dataDownload",
        component: DataDownload,
        redirect: "/dataDownload/index", //默认显示
        meta: {
          title: "资料下载",
        },
        children: [{
            path: "/dataDownload/index",
            name: "dataDownload/index",
            component: () => import("../views/dataDownload/downloadIndex.vue"),
            meta: {
              title: "资料下载",
            },
          },
          {
            path: "/dataDownload/list",
            name: "dataDownload/list",
            component: () => import("../views/dataDownload/list.vue"),
            meta: {
              title: "资料列表",
            },
          },
          {
            path: "/dataDownload/details",
            name: "dataDownload/details",
            component: () => import("../views/dataDownload/details.vue"),
            meta: {
              title: "资料详情",
            },
          },
          {
            path: "/dataDownload/order",
            name: "dataDownload/order",
            component: () => import("../views/dataDownload/order.vue"),
            meta: {
              title: "订单结算",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/onlineQuestionBank",
        name: "在线题库",
        component: OnlineQuestionBank,
        redirect: "/onlineQuestionBank/index", //默认显示
        meta: {
          title: "在线题库",
        },
        children: [{
            path: "/onlineQuestionBank/index",
            name: "onlineQuestionBank/index",
            component: () =>
              import("../views/onlineQuestionBank/questionBankIndex.vue"),
            meta: {
              title: "在线题库",
            },
          },
          {
            path: "/onlineQuestionBank/details",
            name: "onlineQuestionBank/details",
            component: () => import("../views/onlineQuestionBank/details.vue"),
            meta: {
              title: "答题模式",
            },
          },
          {
            path: "/onlineQuestionBank/practiceDetails",
            name: "onlineQuestionBank/practiceDetails",
            component: () =>
              import("../views/onlineQuestionBank/practiceDetails.vue"),
            meta: {
              title: "习题详情",
            },
          },
          {
            path: "/onlineQuestionBank/answer",
            name: "onlineQuestionBank/answer",
            component: () => import("../views/onlineQuestionBank/answer.vue"),
            meta: {
              title: "在线答题",
              requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/randomAnswer",
            name: "onlineQuestionBank/randomAnswer",
            component: () =>
              import("../views/onlineQuestionBank/randomAnswer.vue"),
            meta: {
              title: "随机答题",
              requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/recitationMode",
            name: "onlineQuestionBank/recitationMode",
            component: () =>
              import("../views/onlineQuestionBank/recitationMode.vue"),
            meta: {
              title: "背题模式",
              requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/chapterExercises",
            name: "onlineQuestionBank/chapterExercises",
            component: () =>
              import("../views/onlineQuestionBank/chapterExercises.vue"),
            meta: {
              title: "章节练习",
              requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/examinationResults",
            name: "onlineQuestionBank/examinationResults",
            component: () =>
              import("../views/onlineQuestionBank/examinationResults.vue"),
            meta: {
              title: "考试结果",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/examRecordDetails",
            name: "onlineQuestionBank/examRecordDetails",
            component: () =>
              import("../views/onlineQuestionBank/examRecordDetails.vue"),
            meta: {
              title: "考试详情",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/practiceRecordDetails",
            name: "onlineQuestionBank/practiceRecordDetails",
            component: () =>
              import("../views/onlineQuestionBank/practiceRecordDetails.vue"),
            meta: {
              title: "练习详情",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/lexicalGrammar",
            name: "onlineQuestionBank/lexicalGrammar",
            component: () =>
              import("../views/onlineQuestionBank/lexicalGrammar.vue"),
            meta: {
              title: "词汇与语法",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/vocabulary",
            name: "onlineQuestionBank/vocabulary",
            component: () =>
              import("../views/onlineQuestionBank/vocabulary.vue"),
            meta: {
              title: "练习",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/translate",
            name: "onlineQuestionBank/translate",
            component: () =>
              import("../views/onlineQuestionBank/translate.vue"),
            meta: {
              title: "翻译",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/writing",
            name: "onlineQuestionBank/writing",
            component: () =>
              import("../views/onlineQuestionBank/writing.vue"),
            meta: {
              title: "写作",
              // requireAuth: true,
            },
          },
          {
            path: "/onlineQuestionBank/lexicalGrammarPractice",
            name: "onlineQuestionBank/lexicalGrammarPractice",
            component: () => import("../views/onlineQuestionBank/lexicalGrammarPractice.vue"),
            meta: {
              title: "词汇与语法",
            },
          }, {
            path: "/onlineQuestionBank/translatePractice",
            name: "onlineQuestionBank/translatePractice",
            component: () => import("../views/onlineQuestionBank/translatePractice.vue"),
            meta: {
              title: "翻译",
            },
          }, {
            path: "/onlineQuestionBank/writingPractice",
            name: "onlineQuestionBank/writingPractice",
            component: () => import("../views/onlineQuestionBank/writingPractice.vue"),
            meta: {
              title: "写作",
            },
          },
          {
            path: "/onlineQuestionBank/order",
            name: "onlineQuestionBank/order",
            component: () => import("../views/onlineQuestionBank/order.vue"),
            meta: {
              title: "订单结算",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/onDemand",
        name: "onDemand",
        component: () => import("../views/play/onDemand.vue"),
        meta: {
          title: "点播",
          // requireAuth: true,
        },
      },
      {
        path: "/liveBroadcast",
        name: "liveBroadcast",
        component: () => import("../views/play/liveBroadcast.vue"),
        meta: {
          title: "直播",
          requireAuth: true,
        },
      },
      {
        path: "/shopping/shoppingCart",
        name: "shopping/shoppingCart",
        component: () => import("../views/shopping/shoppingCart.vue"),
        meta: {
          title: "购物车",
          requireAuth: true,
        },
      },
      {
        path: "/personal",
        name: "personal",
        component: Personal,
        redirect: "/personal/myCourse", //默认显示
        meta: {
          title: "我的课程",
          requireAuth: true,
        },
        children: [{
            path: "/personal/personalData",
            name: "personal/personalData",
            component: () => import("../views/personal/personalData.vue"),
            meta: {
              title: "个人资料",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myCourse",
            name: "personal/myCourse",
            component: () => import("../views/personal/myCourse.vue"),
            meta: {
              title: "我的课程",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myTimetable",
            name: "personal/myTimetable",
            component: () => import("../views/personal/myTimetable.vue"),
            meta: {
              title: "我的课表",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myTask",
            name: "personal/myTask",
            component: () => import("../views/personal/myTask.vue"),
            meta: {
              title: "我的作业",
              requireAuth: true,
            },
          },
          {
            path: "/personal/questionCenter",
            name: "personal/questionCenter",
            component: () => import("../views/personal/questionCenter.vue"),
            meta: {
              title: "习题中心",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myPractice/selectCouse",
            name: "personal/myPractice/selectCouse",
            component: () =>
              import("../views/personal/myPractice/selectCouse.vue"),
            meta: {
              title: "我的练习-选择课程",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myPractice/practice",
            name: "personal/myPractice/practice",
            component: MyPractice,
            redirect: "/personal/myPractice/practiceNotes", //默认显示
            meta: {
              title: "我的练习-练习",
              requireAuth: true,
            },
            children: [{
                path: "/personal/myPractice/practiceNotes",
                name: "personal/myPractice/practiceNotes",
                component: () =>
                  import("../views/personal/myPractice/practiceNotes.vue"),
                meta: {
                  title: "练习记录",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/examinationRecord",
                name: "personal/myPractice/examinationRecord",
                component: () =>
                  import("../views/personal/myPractice/examinationRecord.vue"),
                meta: {
                  title: "考试记录",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/recordOfBrushingQuestions",
                name: "personal/myPractice/recordOfBrushingQuestions",
                component: () =>
                  import(
                    "../views/personal/myPractice/recordOfBrushingQuestions.vue"
                  ),
                meta: {
                  title: "刷题记录",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/wrongQuestionRecord",
                name: "personal/myPractice/wrongQuestionRecord",
                component: () =>
                  import(
                    "../views/personal/myPractice/wrongQuestionRecord.vue"
                  ),
                meta: {
                  title: "错题记录",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/answerAnalysis",
                name: "personal/myPractice/answerAnalysis",
                component: () =>
                  import("../views/personal/myPractice/answerAnalysis.vue"),
                meta: {
                  title: "答案解析",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/afterPractice",
                name: "personal/myPractice/afterPractice",
                component: () =>
                  import("../views/personal/myPractice/afterPractice.vue"),
                meta: {
                  title: "课后练习",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/newWordBook",
                name: "personal/myPractice/newWordBook",
                component: () =>
                  import("../views/personal/myPractice/newWordBook.vue"),
                meta: {
                  title: "生词本",
                  requireAuth: true,
                },
              },
              {
                path: "/personal/myPractice/sentencePatternAnalysis",
                name: "personal/myPractice/sentencePatternAnalysis",
                component: () =>
                  import("../views/personal/myPractice/sentencePatternAnalysis.vue"),
                meta: {
                  title: "句式分析",
                  requireAuth: true,
                },
              },
            ],
          },
          {
            path: "/personal/myData",
            name: "personal/myData",
            component: () => import("../views/personal/myData.vue"),
            meta: {
              title: "我的资料",
              requireAuth: true,
            },
          },
          {
            path: "/personal/myOrder",
            name: "personal/myOrder",
            component: () => import("../views/personal/myOrder.vue"),
            meta: {
              title: "我的订单",
              requireAuth: true,
            },
          },
          {
            path: "/personal/news",
            name: "personal/news",
            component: () => import("../views/personal/news.vue"),
            meta: {
              title: "消息",
              requireAuth: true,
            },
          },
          {
            path: "/personal/integral",
            name: "personal/integral",
            component: () => import("../views/personal/integral.vue"),
            meta: {
              title: "积分",
              requireAuth: true,
            },
          },
          {
            path: "/personal/puliPoint",
            name: "personal/puliPoint",
            component: () => import("../views/personal/puliPoint.vue"),
            meta: {
              title: "普利点",
              requireAuth: true,
            },
          },
          {
            path: "/personal/coupon",
            name: "personal/coupon",
            component: () => import("../views/personal/coupon.vue"),
            meta: {
              title: "优惠券",
              requireAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: ForgetPassword,
    meta: {
      title: "忘记密码",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "注册",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/H5/testPaper",
    name: "H5/testPaper",
    component: TestPaper,
    meta: {
      title: "在线答题",
    },
  },
  {
    path: "/H5/topicPaper",
    name: "H5/topicPaper",
    component: TopicPaper,
    meta: {
      title: "名词刷题",
    },
  },
  {
    path: "/H5/chapterExercises",
    name: "H5/chapterExercises",
    component: ChapterExercises,
    meta: {
      title: "章节练习",
    },
  },
  {
    path: "/H5/examinationResults",
    name: "H5/examinationResults",
    component: ExaminationResults,
    meta: {
      title: "考试结果",
    },
  },
  {
    path: "/H5/practice",
    name: "H5/practice",
    redirect: "/H5/practice/index", //默认显示
    component: () => import("../views/H5/practice/index.vue"),
    meta: {
      title: "练习记录",
      keepAlive: true,
    },
    children: [{
        path: "/H5/practice",
        name: "H5/practice",
        component: () => import("../views/H5/practice/practice.vue"),
        meta: {
          title: "练习记录",
          keepAlive: true,
        },
      },
      {
        path: "/H5/practiceRecordDetails",
        name: "H5/practiceRecordDetails",
        component: () => import("../views/H5/practice/practiceRecordDetails.vue"),
        meta: {
          title: "练习详情",
        },
      },
      {
        path: "/H5/practiceList",
        name: "H5/practiceList",
        component: () => import("../views/H5/practice/practiceList.vue"),
        meta: {
          title: "练习",
        },
      },
      {
        path: "/H5/practice/lexicalGrammar",
        name: "H5/practice/lexicalGrammar",
        component: () => import("../views/H5/practice/lexicalGrammar.vue"),
        meta: {
          title: "词汇与语法",
        },
      }, {
        path: "/H5/practice/translate",
        name: "H5/practice/translate",
        component: () => import("../views/H5/practice/translate.vue"),
        meta: {
          title: "翻译",
        },
      }, {
        path: "/H5/practice/writing",
        name: "H5/practice/writing",
        component: () => import("../views/H5/practice/writing.vue"),
        meta: {
          title: "写作",
        },
      }
    ],
  },
  {
    path: "/H5/examRecordDetails",
    name: "H5/examRecordDetails",
    component: () => import("../views/H5/examRecordDetails.vue"),
    meta: {
      title: "考试详情",
    },
  },
  {
    path: "/H5/studentTimetable",
    name: "H5/studentTimetable",
    component: () => import("../views/H5/studentTimetable.vue"),
    meta: {
      title: "我的课表",
    },
  },
  {
    path: "/H5/checkRecord",
    name: "H5/checkRecord",
    component: () => import("../views/H5/checkRecord/index.vue"),
    meta: {
      title: "签到记录",
      keepAlive: true,
    },
  },
  {
    path: "/H5/checkRecordDetails",
    name: "H5/checkRecordDetails",
    component: () => import("../views/H5/checkRecord/details.vue"),
    meta: {
      title: "签到详情",
    },
  },
  {
    path: "/H5/informationList",
    name: "H5/informationList",
    component: () => import("../views/H5/informationList/index.vue"),
    meta: {
      title: "艺术资讯",
      keepAlive: true,
    },
  },
  {
    path: "/H5/informationListDetails",
    name: "H5/informationListDetails",
    component: () => import("../views/H5/informationList/details.vue"),
    meta: {
      title: "资讯详情",
    },
  },
  {
    path: "/H5/teacherWorks",
    name: "H5/teacherWorks",
    component: () => import("../views/H5/teacherWorks/index.vue"),
    meta: {
      title: "教师作品",
    },
  },
  {
    path: "/H5/teacherWorksDetails",
    name: "H5/teacherWorksDetails",
    component: () => import("../views/H5/teacherWorks/details.vue"),
    meta: {
      title: "作品详情",
    },
  },
  {
    path: "/H5/questionCenter",
    name: "H5/questionCenter",
    redirect: "/H5/questionCenter/index", //默认显示
    component: () => import("../views/H5/questionCenter/index.vue"),
    meta: {
      title: "习题中心",
      // keepAlive: true,
    },
    children: [{
      path: "/H5/questionCenter",
      name: "H5/questionCenter",
      component: () => import("../views/H5/questionCenter/questionCenter.vue"),
      meta: {
        title: "习题中心",
        keepAlive: true,
      },
    }, {
      path: "/H5/questionCenter/lexicalGrammar",
      name: "H5/questionCenter/lexicalGrammar",
      component: () => import("../views/H5/questionCenter/lexicalGrammar.vue"),
      meta: {
        title: "词汇与语法",
      },
    }, {
      path: "/H5/questionCenter/translate",
      name: "H5/questionCenter/translate",
      component: () => import("../views/H5/questionCenter/translate.vue"),
      meta: {
        title: "翻译",
      },
    }, {
      path: "/H5/questionCenter/writing",
      name: "H5/questionCenter/writing",
      component: () => import("../views/H5/questionCenter/writing.vue"),
      meta: {
        title: "写作",
      },
    }, {
      path: "/H5/questionCenter/setGoals",
      name: "H5/questionCenter/setGoals",
      component: () => import("../views/H5/questionCenter/setGoals.vue"),
      meta: {
        title: "设置学习目标",
      },
    }, {
      path: "/H5/questionCenter/punchCalendar",
      name: "H5/questionCenter/punchCalendar",
      component: () => import("../views/H5/questionCenter/punchCalendar.vue"),
      meta: {
        title: "打卡日历",
      },
    }, {
      path: "/H5/questionCenter/vocabulary",
      name: "H5/questionCenter/vocabulary",
      component: () => import("../views/H5/questionCenter/vocabulary.vue"),
      meta: {
        title: "真题词汇",
      },
    }],
  },
  {
    path: "/H5/filePreview",
    name: "H5/filePreview",
    component: () => import("../views/H5/filePreview.vue"),
    meta: {
      title: "文件预览",
    },
  },
  {
    path: "/H5/art",
    name: "H5/art",
    redirect: "/H5/art/index", //默认显示
    component: () => import("../views/H5/art/index.vue"),
    meta: {
      title: "在线艺术",
    },
    children: [{
        path: "/H5/art/index",
        name: "H5/art/index",
        component: () => import("../views/H5/art/artExhibition1.vue"),
        meta: {
          title: "在线艺术",
        },
      },
      {
        path: "/H5/art/artExhibitionDetail",
        name: "H5/art/artExhibitionDetail",
        component: () => import("../views/H5/art/artExhibitionDetail.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/H5/art/list",
        name: "H5/art/list",
        component: () => import("../views/H5/art/list.vue"),
        meta: {
          title: "艺术列表",
        },
      },
      {
        path: "/H5/art/authorDetails",
        name: "H5/art/authorDetails",
        component: () => import("../views/H5/art/authorDetails.vue"),
        meta: {
          title: "作者",
        },
      },
      {
        path: "/H5/art/artistRegister",
        name: "H5/art/artistRegister",
        component: () => import("../views/H5/art/artistRegister.vue"),
        meta: {
          title: "艺术家入驻",
        },
      },
      {
        path: "/H5/art/artists",
        name: "H5/art/artists",
        component: () => import("../views/H5/art/artists.vue"),
        meta: {
          title: "艺术家列表",
        },
      },
      {
        path: "/H5/art/artistsDetails",
        name: "H5/art/artistsDetails",
        component: () => import("../views/H5/art/artistsDetails.vue"),
        meta: {
          title: "作品详情",
        },
      },
      {
        path: "/H5/art/artistIntroduction",
        name: "H5/art/artistIntroduction",
        component: () => import("../views/H5/art/artistIntroduction.vue"),
        meta: {
          title: "艺术家",
        },
      },
      {
        path: "/H5/art/artworkAdd",
        name: "H5/art/artworkAdd",
        component: () => import("../views/H5/art/artworkAdd.vue"),
        meta: {
          title: "作品发布",
        },
      }
    ],
  },
  {
    path: "/H5/fineArts",
    name: "H5/fineArts",
    redirect: "/H5/fineArts", //默认显示
    component: () => import("../views/H5/fineArts/index.vue"),
    meta: {
      title: "三合美术馆",
    },
    children: [{
        path: "/H5/fineArts",
        name: "H5/fineArts",
        component: () => import("../views/H5/fineArts/fineArts.vue"),
        meta: {
          title: "三合美术馆",
        },
      },
      {
        path: "/H5/fineArts/details",
        name: "H5/fineArts/details",
        component: () => import("../views/H5/fineArts/details.vue"),
        meta: {
          title: "三合美术馆详情",
        },
      }, {
        path: "/H5/fineArts/expert",
        name: "H5/fineArts/expert",
        component: () => import("../views/H5/fineArts/expert.vue"),
        meta: {
          title: "专家团队",
        },
      }, {
        path: "/H5/fineArts/informationList",
        name: "H5/fineArts/informationList",
        component: () => import("../views/H5/fineArts/informationList.vue"),
        meta: {
          title: "资讯列表",
        },
      },
      {
        path: "/H5/fineArts/newsList",
        name: "H5/fineArts/newsList",
        component: () => import("../views/H5/fineArts/newsList.vue"),
        meta: {
          title: "资讯列表",
        },
      },
      {
        path: "/H5/fineArts/expert",
        name: "H5/fineArts/expert",
        component: () => import("../views/H5/fineArts/expert.vue"),
        meta: {
          title: "专家团队",
        },
      }, {
        path: "/H5/fineArts/produceDetail",
        name: "H5/fineArts/produceDetail",
        component: () => import("../views/H5/fineArts/produceDetail.vue"),
        meta: {
          title: "作品详情",
        },
      }
    ],
  },
  {
    path: "/H5/bbs",
    name: "H5/bbs",
    //component: Bbs,
    redirect: "/H5/bbs/Index", //默认显示
    component: () => import("../views/H5/bbs/Index.vue"),
    meta: {
      title: "艺术交流",
    },
    children: [{
        path: "/H5/bbs/Index",
        name: "H5/bbs/Index",
        component: () => import("../views/H5/bbs/Index1.vue"),
        meta: {
          title: "艺术交流",
        },
      },
      {
        path: "/H5/bbs/detail",
        name: "H5/bbs/detail",
        component: () => import("../views/H5/bbs/detail.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/H5/bbs/myTopic",
        name: "H5/bbs/myTopic",
        component: () => import("../views/H5/bbs/myTopic.vue"),
        meta: {
          title: "我的动态",
        },
      },
      {
        path: "/H5/bbs/myCollection",
        name: "H5/bbs/myCollection",
        component: () => import("../views/H5/bbs/myCollection.vue"),
        meta: {
          title: "我的收藏",
        },
      },
      {
        path: "/H5/bbs/edit",
        name: "H5/bbs/edit",
        component: () => import("../views/H5/bbs/edit.vue"),
        meta: {
          title: "发帖",
        },
      },
    ],
  },
  {
    path: "/H5/studentTask/index",
    name: "H5/studentTask/index",
    redirect: "/H5/studentTask/index", //默认显示
    component: () => import("../views/H5/studentTask/index.vue"),
    meta: {
      title: "我的作业",
    },
    children: [{
        path: "/H5/studentTask/index",
        name: "H5/studentTask/index",
        component: () => import("../views/H5/studentTask/taskList.vue"),
        meta: {
          title: "我的作业",
        },
      },
      {
        path: "/H5/studentTask/detail",
        name: "H5/studentTask/detail",
        component: () => import("../views/H5/studentTask/detail.vue"),
        meta: {
          title: "详情",
        },
      }
    ],
  },
  {
    path: "/H5/noticeList",
    name: "H5/noticeList",
    redirect: "/H5/noticeList/index", //默认显示101
    component: () => import("../views/H5/noticeList/index.vue"),
    meta: {
      title: "通知列表",
    },
    children: [{
        path: "/H5/noticeList/index",
        name: "H5/noticeList/index",
        component: () => import("../views/H5/noticeList/list.vue"),
        meta: {
          title: "通知列表",
        },
      },
      {
        path: "/H5/noticeList/details",
        name: "H5/noticeList/details",
        component: () => import("../views/H5/noticeList/details.vue"),
        meta: {
          title: "通知详情",
        },
      }
    ],
  },
  // 普利商城
  {
    path: "/shop",
    name: "shop",
    component: Shop,
    redirect: "/shop/home", //默认显示
    meta: {
      title: "首页",
    },
    children: [{
        path: "/shop/home",
        name: "shop/home",
        component: () => import("../views/shop/home.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/shop/translation",
        name: "shop/translation",
        component: () => import("../views/shop/translation.vue"),
        meta: {
          title: "分类",
        },
      },
      {
        path: "/shop/ShopCar",
        name: "shop/ShopCar",
        component: () => import("../views/shop/ShopCar.vue"),
        meta: {
          title: "购物车",
        },
      },
      {
        path: "/shop/my",
        name: "shop/my",
        component: () => import("../views/shop/my.vue"),
        meta: {
          title: "我的",
        },
      },
      {
        path: "/shop/goods/list",
        name: "shop/goods/list",
        component: () => import("../views/shop/goods/list.vue"),
        meta: {
          title: "商品列表",
        },
      },

      {
        path: "/shop/goods/detail/",
        name: "shop/goods/detail",
        component: () => import("../views/shop/goods/detail.vue"),
        meta: {
          title: "商品详情",
        },
      },
      {
        path: "/shop/goods/comment/:id",
        name: "shop/goods/comment",
        component: () => import("../views/shop/goods/comment.vue"),
        meta: {
          title: "评论",
        },
      },

      {
        path: "/shop/orders",
        name: "shop/orders",
        redirect: "/shop/orders/index", //默认显示
        component: () => import("../views/shop/orders/index.vue"),
        meta: {
          title: "订单",
        },
        children: [
          //订单列表
          {
            path: "/shop/orders/index",
            name: "list",
            component: () => import("../views/shop/orders/list.vue"),
            meta: {
              title: "我的订单",
            },
          },
          {
            path: "/shop/orders/detail",
            name: "detail",
            component: () => import("../views/shop/orders/detail.vue"),
            meta: {
              title: "订单详情",
            },
          },
          {
            path: "/shop/orders/logistics",
            name: "logistics",
            component: () => import("../views/shop/orders/logistics.vue"),
            meta: {
              title: "订单物流",
            },
          },
          {
            path: "/shop/orders/refund",
            name: "refund",
            component: () => import("../views/shop/orders/refund.vue"),
            meta: {
              title: "选择退款类型",
            },
          },
          {
            path: "/shop/orders/applyRefund",
            name: "applyRefund",
            component: () => import("../views/shop/orders/applyRefund.vue"),
            meta: {
              title: "申请退款",
            },
          },
          {
            path: "/shop/orders/refundDetail",
            name: "refundDetail",
            component: () => import("../views/shop/orders/refundDetail.vue"),
            meta: {
              title: "退款详情",
            },
          },
          {
            path: "/shop/orders/afterSales",
            name: "afterSales",
            component: () => import("../views/shop/orders/afterSales.vue"),
            meta: {
              title: "退款/售后",
            },
          },
          {
            path: "/shop/orders/shareImg",
            name: "shareImg",
            component: () => import("../views/shop/orders/shareImg.vue"),
            meta: {
              title: "画报分享",
            },
          },
          {
            path: "/shop/orders/confirmOrder",
            name: "confirmOrder",
            component: () => import("../views/shop/orders/confirmOrder.vue"),
            meta: {
              title: "确认订单",
            },
          },
          {
            path: "/shop/orders/payment",
            name: "payment",
            component: () => import("../views/shop/orders/payment.vue"),
            meta: {
              title: "支付",
            },
          },
          {
            path: "/shop/orders/evaluate",
            name: "evaluate",
            component: () => import("../views/shop/orders/evaluate.vue"),
            meta: {
              title: "发布评价",
            },
          },
        ],
      },
      {
        path: "/shop/pointsmall/index",
        name: "shop/pointsmall/index",
        component: () => import("../views/shop/pointsmall/index.vue"),
        meta: {
          title: "积分商城",
        },
      },

      {
        path: "/shop/pointsmall/detail",
        name: "shop/pointsmall/detail",
        component: () => import("../views/shop/pointsmall/detail.vue"),
        meta: {
          title: "积分详情",
        },
      },

      {
        path: "/shop/address/index",
        name: "shop/address/index",
        component: () => import("../views/shop/address/index.vue"),
        meta: {
          title: "地址管理",
        },
      },

      {
        path: "/shop/address/detail",
        name: "shop/address/detail",
        component: () => import("../views/shop/address/detail.vue"),
        meta: {
          title: "地址",
        },
      },

      {
        path: "/shop/user/Discount",
        name: "shop/user/Discount",
        component: () => import("../views/shop/user/Discount.vue"),
        meta: {
          title: "我的优惠券",
        },
      },
      {
        path: "/shop/service/index",
        name: "shop/service/index",
        component: () => import("../views/shop/service/index.vue"),
        meta: {
          title: "在线客服",
        },
      },
    ]
  },
  //普利商场PC
  // 普利商城
  {
    path: "/PCshop/PChome",
    name: "PCshop/PChome",
    component: () => import("../views/PCshop/PChome.vue"),
    meta: {
      title: "PC首页",
    },
  },
  {
    path: "/PCshop/ShopCar",
    name: "PCshop/ShopCar",
    component: () => import("../views/PCshop/ShopCar.vue"),
    meta: {
      title: "购物车",
    },
  },
  {
    path: "/PCshop/goods/list",
    name: "PCshop/goods/list",
    component: () => import("../views/PCshop/goods/list.vue"),
    meta: {
      title: "商品列表",
    },
  },
  {
    path: "/PCshop/goods/goodsdetail",
    name: "PCshop/goods/goodsdetail",
    component: () => import("../views/PCshop/goods/goodsdetail.vue"),
    meta: {
      title: "商品详情",
    },
  },
  {
    path: "/PCshop/goods/integral",
    name: "PCshop/goods/integral",
    component: () => import("../views/PCshop/goods/integral.vue"),
    meta: {
      title: "积分商城",
    },
  },
  {
    path: "/PCshop/my/shopmy",
    name: "PCshop/my/shopmy",
    component: () => import("../views/PCshop/my/shopmy.vue"),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/PCshop/my/orderdetail",
    name: "PCshop/my/orderdetail",
    component: () => import("../views/PCshop/my/orderdetail.vue"),
    meta: {
      title: "订单详情",
    },
  },
  {
    path: "/PCshop/goods/check",
    name: "PCshop/goods/check",
    component: () => import("../views/PCshop/goods/check.vue"),
    meta: {
      title: "订单结算",
    },
  },
  {
    path: "/PCshop/my/pay",
    name: "PCshop/my/pay",
    component: () => import("../views/PCshop/my/pay.vue"),
    meta: {
      title: "支付方式",
    },
  },
  // 404页面
  {
    path: "/PCshop/goods/goodssucc",
    name: "PCshop/goods/goodssucc",
    component: () => import("../views/PCshop/goods/goodssucc.vue"),
    meta: {
      title: "支付成功"
    },
  },
  {
    path: "/PCshop/service/applyservice",
    name: "/PCshop/service/applyservice",
    component: () => import("../views//PCshop/service/applyservice.vue"),
    meta: {
      title: "申请售后"
    },
  },
  {
    path: "/PCshop/service/servicedetail",
    name: "/PCshop/service/servicedetail",
    component: () => import("../views/PCshop/service/servicedetail.vue"),
    meta: {
      title: "售后详情"
    },
  },
  {
    path: "/PCshop/goods/evaluate",
    name: "/PCshop/goods/evaluate",
    component: () => import("../views/PCshop/goods/evaluate.vue"),
    meta: {
      title: "售后详情"
    },
  },
  {
    path: "/PCshop/goods/refundDetail",
    name: "/PCshop/goods/refundDetail",
    component: () => import("../views/PCshop/goods/refundDetail.vue"),
    meta: {
      title: "退款详情"
    },
  },
  {
    path: "/PCshop/footerdetail",
    name: "/PCshop/footerdetail",
    component: () => import("../views/PCshop/footerdetail/index.vue"),
    meta: {
      title: "公司情况"
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import( /* webpackChunkName: "404" */ "../views/404.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/H5/videoPlayer",
    name: "/H5/videoPlayer",
    component: () => import("../views/H5/video/videoPlayer.vue"),
    meta: {
      title: ""
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // 解决vue路由跳转页面，滚动条默认在页面底部的问题
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// 添加判断pc端移动端方法
function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

//  需要登录才能进入的页面可以增加一个meta属性requireAuth
// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  console.log()
  // 动态title
  document.title = to.meta.title ? to.meta.title : "普利教育";
  if (to.matched.some((res) => res.meta.requireAuth)) {
    // 判断是否需要登录权限
    if (localStorage.getItem("userInfo")) {
      // 判断是否登录
      next();
    } else {
      console.log(isMobile())
      // 没登录则跳转到登录界面
      if (isMobile()) {
        window.location.href = 'https://yxyapp.micmark.com/app/pages/login/login'
      } else {
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      }

    }
  } else {
    next();
  }
});

export default router;