/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:33 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 15:29:33 
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";

// 获取帖子列表
export async function queryAllTopicList(params) {
    let res = await http.post(`${resquest}/BBS/QueryAllTopicList`, params)
    return res.data;
}

// 获取回帖列表
export async function queryReplyList(params) {
    let res = await http.post(`${resquest}/BBS/QueryReplyList`, params)
    return res.data;
}

// 回帖
export async function saveReply(params) {
    console.log(params)
    let res = await http.post(`${resquest}/BBS/SaveReply`, params)
    return res.data;
}

// 发帖
export async function saveTopic(params) {
    console.log(params)
    let res = await http.post(`${resquest}/BBS/SaveTopic`, params)
    return res.data;
}

// 赞同/取消赞同
export async function topicAgree(params) {
    let res = await http.post(`${resquest}/BBS/TopicAgree`, params)
    return res.data;
}

// 收藏/取消收藏
export async function topicCollection(params) {
    let res = await http.post(`${resquest}/BBS/TopicCollection`, params)
    return res.data;
}


// 获取论坛版块信息
export async function querySectionList(params) {
    let res = await http.post(`${resquest}/BBS/QuerySectionList`, params)
    return res.data;
}


// 获取帖子详情
export async function queryTopicDetail(params) {
    let res = await http.get(`${resquest}/BBS/QueryTopicDetail?id=` + params)
    return res.data;
}

// 修改浏览次数
export async function updateTopicReadNum(params) {
    let res = await http.get(`${resquest}/BBS/UpdateTopicReadNum?id=` + params)
    return res.data;
}

// 删除帖子
export async function deleteTopic(params) {
    let res = await http.get(`${resquest}/BBS/DeleteTopic?id=` + params)
    return res.data;
}

// 获取我的动态和收藏数量
export async function queryMyTopicCount(params) {
    let res = await http.get(`${resquest}/BBS/QueryMyTopicCount?studentId=` + params)
    return res.data;
}

// 根据Id获取学生信息
export async function queryStudentInfoById(params) {
    let res = await http.get(`${resquest}/Student/QueryStudentInfoById?id=` + params)
    return res.data;
}