<template>
  <div>
    <div class="sus">
      <div class="top">
        <div
          class="susitem"
          v-for="(item, index) in suslist"
          :key="index"
          @click="change(index)"
        >
          <div class="sus-img">
            <img :src="item.img" alt="" />
          </div>
          <div :class="[act == index ? 'susitem-font2' : 'susitem-font']">
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="topicon" @click="toTop"></div>
    </div>
    <!-- 用户反馈 -->
    <el-dialog
      title="用户反馈"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
      center
    >
      <div class="dialong-item">
        <div class="item-left">姓名</div>
        <el-input
          v-model="name"
          placeholder="不能为昵称、X先生、X小姐等，请使用真实姓名"
        ></el-input>
      </div>
      <div class="dialong-item">
        <div class="item-left">手机号码</div>
        <el-input v-model="tel" placeholder="请填写"></el-input>
      </div>
      <div class="dialong-item">
        <div class="item-left">内容</div>
        <el-input
          v-model="content"
          placeholder="请填写"
          type="textarea"
          rows="4"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="yijian">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="在线客服"
      :visible.sync="ewm"
      width="650px"
      :before-close="handleCloseEwm"
      center
    >
      <div class="service" style="width:100%;height: 100%;">
        <img
          
            src="../../../assets/images/oneLineService.png"
            alt=""
        />
      </div>
    </el-dialog>
    <el-dialog
      title="APP下载"
      :visible.sync="appShow"
      width="750px"
      :before-close="handleCloseApp"
      center
    >
      <div class="download">
        <img src="@/assets/images/download.png" width="100%" alt="" srcset="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { queryOnlineService, querFeedback } from "@/api/shop";
export default {
  data () {
    return {
      act: 100,
      name: '',
      tel: '',
      content: '',
      dialogVisible: false,
      gotop: false,
      ewm: false,
      appShow: false,
      // isfankui:false,
      suslist: [
        {
          img: require('../../../assets/images/kefu.png'),
          text: '在线客服',
        },
        {
          img: require('../../../assets/images/fankui.png'),
          text: '用户反馈',
        },
        {
          img: require('../../../assets/images/phone.png'),
          text: 'APP下载',
        }],
      kefu: '',
      userInfo: {}
    }
  },
  created () {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
  },
  mounted () {　　// 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
    this.QueryOnlineService()
  },
  methods: {
    handleCloseApp () {
      this.appShow = false;
    },
    handleCloseEwm () {
      this.ewm = false;
    },
    handleClose () {
      this.dialogVisible = false;
      this.act = 100
    },
    quxiao () {
      this.dialogVisible = false;
      this.act = 100
    },
    yijian () { //点击意见提交
      if (this.name == '') {
        this.$message({
          message: "请输入姓名",
          type: "warning"
        });
        return
      }
      if (this.tel == '' || this.tel.length != 11) {
        this.$message({
          message: "请输入正确手机号码",
          type: "warning"
        });
        return false
      }
      if (this.content == '') {
        this.$message({
          message: "请输入内容",
          type: "warning"
        });
        return
      }
      this.QuerFeedback();
    },
    //意见反馈
    async QuerFeedback () {
      let parms = {}
      parms.ContactUser = this.name //姓名
      parms.StudentId = this.userInfo.Id //学生id
      const res = await querFeedback(parms)
      console.log(res);
      if (res.success) {
        this.$message({
          message: res.msg,
          type: "success"
        });
      } else {
        this.$message.error("反馈失败");
      }
      this.dialogVisible = false
      this.act = 100
    },
    //在线客服
    async QueryOnlineService () {
      const res = await queryOnlineService()
      this.kefu = res.response.OnlineService
    },
    handleScroll () {
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    change (idx) {
      this.act = idx;
      if (idx == 0) {
        this.ewm = !this.ewm
      }
      if (idx == 1) {
        this.ewm = false
        this.dialogVisible = true
        // console.log('子组件')
      }
      if (idx == 2) {
        this.appShow = !this.appShow
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.zhezhao {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.sus {
  position: fixed;
  top: 30%;
  right: 80px;
  z-index: 9;
  .top {
    width: 74px;
    border: 1px solid #e6e6e6;
    padding: 0px 10px 0px 10px;
    background: #ffffff;
  }
  .susitem {
    cursor: pointer;
    height: 67px;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    font-size: 12px;
    color: #666666;
    text-align: center;
    padding-top: 12px;
    .susitem-font {
      margin-top: 6px;
    }
    .susitem-font2 {
      margin-top: 6px;
      color: #f7462c;
    }
  }
}
.topicon {
  margin-top: 5px;
  cursor: pointer;
  // position: fixed;
  // top: 60%;
  // right: 80px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  width: 74px;
  height: 70px;
  background-image: url("../../../assets/images/sustop.png");
  background-repeat: no-repeat;
  //   background-size: 100% 100%;
  background-position: 50% 50%;
  z-index: 9;
}
.service,
.download {
  // position: absolute;
  z-index: 9999;
  width: 300px;
  height: 300px;
  // left: 43%;
  // top: 30%;
  margin: 0 auto;
  /deep/img {
    width: 100%;
  }
}
.download {
  width: 500px;
  height: 400px;
}
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;
  .left {
    width: 17px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.fankui {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 660px;
  height: 439px;
  background: #ffffff;
  z-index: 999;
  margin-left: -330px;
  margin-top: -219px;
  .fankui-title {
    width: 100%;
    height: 73px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #d9d0cd;
    text-align: center;
    line-height: 73px;
  }
  .name {
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .phone {
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content {
    input {
      width: 460px;
      height: 86px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content,
  .phone,
  .name {
    display: flex;
    justify-content: space-between;
    padding-right: 43px;
    margin-top: 34px;
  }
  .text {
    margin-right: 30px;
    margin-left: auto;
    margin-top: 5px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c1c1c1;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c1c1c1;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c1c1c1;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c1c1c1;
  }
}
.dialong-item {
  justify-content: space-around;
  margin-top: 20px;
  display: flex;
  .item-left {
    line-height: 40px;
    min-width: 56px;
    text-align: right;
  }
}
/deep/.el-input {
  width: 420px;
}
/deep/.el-textarea {
  width: 420px;
}
</style>