/* * @Author: liuzhixiang * @Date: 2021-02-06 15:18:49 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-06 15:18:49 */
<template>
  <div class="dialogVisible">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :width="width"
      center
      :before-close="handleClose"
    >
      <el-row type="flex" style="margin-bottom: 10px">
        <el-col :span="4">题目：</el-col>
        <el-col :span="20">{{ obj.Name }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="4">描述：</el-col>
        <el-col :span="20">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入描述"
            v-model="remark"
          />
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <div @click="handleClose()">取 消</div>
        <div @click="handleFind()">确 定</div>
        <!-- <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="handleFind()">确 定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogVisible", "obj", "type", "width"],
  data() {
    return {
      remark: "",
    };
  },
  created() {},
  mounted() {
    console.log(this.obj);
  },
  methods: {
    handleFind() {
      if (this.remark == "") {
        this.$message.error("请输入描述");
        return false;
      }
      let ob = {
        id: this.obj.Id,
        CategoryId: this.type, //2名词刷题 1习题
        CorrectionRemark: this.remark,
      };
      this.$emit("find", ob);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.dialogVisible {
  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 10px;
      overflow: hidden;
      .el-dialog__header {
        background: url("../../assets/images/correction.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 100px;
        .el-dialog__close {
          color: #fff;
        }
      }
      .el-dialog__footer {
        padding: 0;
        .dialog-footer {
          display: flex;
          div {
            width: 50%;
            height: 46px;
            line-height: 46px;
            font-size: 15px;
            text-align: center;
            color: #fff;
            background: #3a78f9;
            &:first-child {
              color: #b8b7b7;
              background: #f3f3f3;
            }
          }
        }
      }
    }
  }
}
</style>
