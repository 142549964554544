/* * @Author: liuzhixiang * @Date: 2021-02-23 11:40:51 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-04 10:23:29 */ //
章节练习：H5/chapterExercises?Id=3&CourseId=9&Name=原始—秦汉&UserID=65
<template>
  <div class="answer" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar title="练习" fixed left-arrow @click-left="onClickLeft" />
    </template>
    <div class="">
      <div class="cont">
        <div class="top">
          <div class="title">{{ number }}/{{ list.length }}</div>
          <i class="el-icon-tickets el-icon" @click="clickSubject()"></i>
        </div>
        <el-row>
          <el-col :span="24">
            <div class="list" v-if="list.length > 0">
              <div class="li" v-for="(li, i) in list" :key="i">
                <div class="paperBank" v-if="number == i + 1">
                  <div class="questionTitle">
                    <p class="tmtype">
                      <span
                        :class="
                          li.QuestionCategory == 0
                            ? 'bgc_3A78F9'
                            : li.QuestionCategory == 1
                            ? 'bgc_FBB03B'
                            : li.QuestionCategory == 2
                            ? 'bgc_00AE62'
                            : li.QuestionCategory == 3
                            ? 'bgc_B938B5'
                            : li.QuestionCategory == 4
                            ? 'bgc_9682EC'
                            : 'bgc_37E6CF'
                        "
                      >
                        {{
                          li.QuestionCategory == 0
                            ? "单选"
                            : li.QuestionCategory == 1
                            ? "多选"
                            : li.QuestionCategory == 2
                            ? "判断"
                            : li.QuestionCategory == 3
                            ? "填空"
                            : li.QuestionCategory == 4
                            ? "简答"
                            : "论述"
                        }}
                      </span>
                      <span>题目{{ i + 1 }}：{{ li.QuestionTitle }}</span>
                    </p>
                    <div class="correction" @click="correction(li)">
                      纠错 <i class="el-icon-edit-outline"></i>
                    </div>
                    <!-- <p class="tmmc">{{lb.QuestionTitle}}</p> -->
                  </div>
                  <el-radio-group
                    v-if="li.QuestionCategory === 0"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-radio :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </el-radio-group>
                  <el-checkbox-group
                    v-else-if="li.QuestionCategory === 1"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-checkbox :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </el-checkbox-group>
                  <el-radio-group
                    v-else-if="li.QuestionCategory === 2"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-radio :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </el-radio-group>
                  <div v-else-if="li.QuestionCategory === 3">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入填空,多个填空以中文；隔开"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </div>
                  <div v-else-if="li.QuestionCategory === 4">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入简答"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </div>
                  <div v-else-if="li.QuestionCategory === 5">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入论述"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】:
                        {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                      </div>
                      <div>
                        【解析】:
                        <span
                          v-html="
                            li.QuestionAnalysis == null
                              ? '无'
                              : li.QuestionAnalysis
                          "
                        ></span>
                      </div>
                      <div>
                        【点拨】: {{ li.Dial == null ? "无" : li.Dial }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list" v-else>
              <div class="li">
                <div class="paperBank">
                  <no-data></no-data>
                </div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="6">
            <div class="list">
              <div v-if="list.length > 0">
                <ul class="li">
                  <li @click="onClick(i+1)" v-for="(li, i) in list" :key="i" :class="li.checkList !='' ? 'bgc_active' : '' ">
                   {{ i + 1 }}
                  </li>
                </ul>
                <div class="text_align_center">
                  <el-button class="buy" @click="buy()">立即提交</el-button>
                </div>
              </div>
            </div>
          </el-col> -->
        </el-row>
        <el-row v-if="list.length > 0">
          <el-col :span="24">
            <div class="qhtm">
              <el-row>
                <el-col :span="8" @click.native="lastQuestion()">
                  <i class="el-icon-arrow-left"></i>
                  上一题
                </el-col>
                <el-col :span="8" @click.native="parsClick()">
                  <img src="../../assets/images/lamp.svg" width="16" />
                  查看答案
                </el-col>
                <el-col :span="8" @click.native="nextQuestion()">
                  下一题
                  <i class="el-icon-arrow-right"></i>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="immediately" v-if="number == list.length"> -->
      <div class="immediately">
        <el-button @click="buy()" class="buy">立即交卷</el-button>
      </div>
    </div>
    <!--纠错弹窗-->
    <dialog-Visible
      v-if="dialogVisible != false"
      type="1"
      :width="width"
      :dialogVisible="dialogVisible"
      :obj="correctionArr"
      @find="getFind"
      @close="getClose()"
    ></dialog-Visible>

    <van-popup
      v-model="dialogVisibleSubject"
      round
      position="bottom"
      :style="{ maxHeight: '70%' }"
    >
      <div class="subject_cont">
        <div class="top">
          <div>题目</div>
          <van-icon name="cross" @click="handleCloseSubject" />
        </div>
        <ul>
          <li @click="onClick(i + 1)" v-for="(li, i) in list" :key="i">
            <span
              :class="
                (li.checkList != '' ? 'bgc_active' : '') ||
                (number == i + 1 ? 'bgc_active1' : '')
              "
              >{{ i + 1 }}</span
            >
          </li>
        </ul>
        <div class="xs">
          <div><i></i>已做</div>
          <div><i></i>未做</div>
          <div><i></i>选中</div>
        </div>
      </div>
    </van-popup>
    <!-- <el-dialog
      title="题目"
      :visible.sync="dialogVisibleSubject"
      width="85%"
      :before-close="handleCloseSubject">
      <div>
        <div class="subject_list">
          <div v-if="list.length > 0">
            <ul class="li">
              <li
                @click="onClick(i + 1)"
                v-for="(li, i) in list"
                :key="i"
                :class="(li.checkList != '' ? 'bgc_active' : '') ||
                ( number == i+1 ? 'bgc_active1' : '')"
              >
                {{ i + 1 }}
              </li>
            </ul>
            <div class="xs">
              <div><i></i>已做</div>
              <div><i></i>未做</div>
              <div><i></i>选中</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSubject = false">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import dialogVisible from "@/components/components/dialogVisible.vue";
import {
  questionBankQueryChapterQuestion,
  questionBankSavePaperRecord,
  questionBankCorrectionQuestion,
} from "@/api/questionBank";
import noData from "@/components/noData";
import common from "@/utils/common.js";
export default {
  data() {
    return {
      answer: {},
      radio: "",
      list: [],
      obj: [],
      number: 1,
      user: {},
      route: {},
      dialogVisible: false,
      dialogVisibleSubject: true,
      width: "",
      correctionArr: {},
      isWxApplets: true,
    };
  },
  components: {
    BreadCrumb,
    dialogVisible,
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "练习";
      this.isWxApplets = false;
    }
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.route = this.$route.query;
    this.init();
    common.timeCounting(true);
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    clickSubject() {
      this.dialogVisibleSubject = true;
    },
    handleCloseSubject() {
      this.dialogVisibleSubject = false;
    },
    async getFind(e) {
      console.log(e);
      const res = await questionBankCorrectionQuestion(e);
      if (res.success === true) {
        this.$message.success("提交成功");
        this.dialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 弹窗关闭
    getClose() {
      this.dialogVisible = false;
    },
    //纠错
    correction(data) {
      this.correctionArr = { Id: data.QuestionId, Name: data.QuestionTitle };
      this.dialogVisible = true;
      this.width = "85%";
    },
    //解析
    parsClick() {
      this.list[this.number - 1].parsingShow = true;
      // console.log(obj, "");
    },
    onClick(i) {
      this.number = i;
    },
    // 上一题
    lastQuestion() {
      if (this.number == 1) {
        //this.number = 1;
        this.$message.info("没有上一题了");
        return false;
      }
      this.number--;
    },
    // 下一题
    nextQuestion() {
      // console.log(this.list[this.number].checkList)
      // console.log(this.list[this.number].checkList)
      if (this.number >= this.list.length) {
        this.number = this.list.length;
        this.$message.info("最后一题");
        return false;
      }
      let i = this.number - 1;
      if (this.list[i].checkList.length == 0 && this.list[i].checkList == "") {
        this.$message.info("未做题目：" + this.list[i].QuestionTitle);
        return false;
      }
      this.number++;
    },
    handChange(b) {
      console.log(b, "");
      let arr = {};
      arr.questionId = b.QuestionId;
      arr.questionCategory = b.QuestionCategory;
      arr.answer = b.checkList.toString();
      let stauts = 0;
      this.obj.forEach((item) => {
        if (item.questionId == arr.questionId) {
          item.answer = arr.answer;
          stauts = 1;
        }
      });
      if (stauts == 0) {
        this.obj.push(arr);
      }
    },
    quitPractice() {
      this.$router.go(-1);
    },
    init() {
      this.answer = this.$route.query;
      this.getquestionBankQueryChapterQuestion();
    },
    //立即交卷
    async buy() {
      let parm = {
        ChapterId: this.answer.Id,
        courseId: this.answer.CourseId,
        studentId: this.route.UserID,
        Category: 1, //0试卷，1章节
        questionItems: this.obj,
        QuestionCategory: this.answer.questionCategory,
      };
      let state = 0;
      if (this.obj == 0) {
        this.$message.info("先答题再提交。");
        return false;
      }
      // this.list.some((item) => {
      //   if (item.checkList.length == 0 && item.checkList == "") {
      //     this.$message.info("未做题目：" + item.QuestionTitle);
      //     state = 1;
      //     return true;
      //   }
      // });
      if (state != 1) {
        const res = await questionBankSavePaperRecord(parm);
        if (res.success === true) {
          this.$message.success(res.msg);
          let obj = {
            type: 2, //1套卷练习2章节练习
            useTime: common.timeCounting(false),
          };
          setTimeout(() => {
            this.$router.push({
              path: "/H5/examinationResults",
              query: { ...res.response, ...obj },
            });
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    //章节练习列表
    async getquestionBankQueryChapterQuestion() {
      let parm =
        "?chapterId=" +
        this.answer.Id +
        "&studentId=" +
        this.answer.UserID +
        "&questionCategory=" +
        this.answer.questionCategory;
      const res = await questionBankQueryChapterQuestion(parm);
      if (res.success === true) {
        this.list = res.response;
        this.list.Name = this.answer.Name;
        this.list.forEach((item) => {
          if (
            item.QuestionCategory === 0 ||
            item.QuestionCategory === 1 ||
            item.QuestionCategory === 2
          ) {
            this.$set(item, "checkList", []);
          } else {
            this.$set(item, "checkList", "");
          }
          this.$set(item, "parsingShow", false);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
  destroyed() {
    common.timeCounting(false);
  },
};
</script>

<style lang="less" scoped>
.answer {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  background-color: #f5f5f5;
  font-size: 14px;
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        line-height: 100%;
        padding: 20px 30px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        i {
          font-weight: bold;
        }
      }
      .el-icon {
        margin-right: 30px;
        font-size: 18px;
      }
    }
    .list {
      min-height: 350px;
      margin-bottom: 15px;
      background-color: #fff;
      .li {
        .paperBank {
          margin-bottom: 5px;
          padding: 20px 30px;
          background-color: #fff;
          .questionTitle {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .correction {
              color: #c5c5c5;
              font-size: 12px;
              width: 40px;
              cursor: pointer;
            }
            p {
              line-height: 22px;
              // height: 22px;
            }
            .tmtype {
              flex: 1;
              span {
                &:first-child {
                  display: inline-block;
                  background-color: #3a78f9;
                  line-height: 22px;
                  height: 22px;
                  color: #fff;
                  padding: 0 8px;
                  border-radius: 20px;
                  margin-right: 10px;
                  font-size: 12px;
                }
              }
            }
            .tmmc {
              margin-top: 10px;
            }
          }
          .el-radio-group,
          .el-checkbox-group {
            .el-checkbox {
              // display: flex;
              // align-items: center;
            }
            .el-radio,
            .el-radio__input,
            .el-checkbox,
            .el-checkbox__input {
              white-space: normal !important;
              line-height: 175%;
            }
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .el-textarea {
            margin-bottom: 10px;
          }
          .js {
            line-height: 175%;
            font-size: 12px;
          }
        }
      }
    }
    ul {
      padding: 20px 30px;
      background-color: #fff;
      overflow: hidden;
      li {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        float: left;
        cursor: pointer;
        border: 1px solid #bbb;
        text-align: center;
        margin: 0 10px 10px 0;
        display: inline-block;
      }
      .bgc_active {
        background-color: #1288f4;
        border: 1px solid #1288f4;
        color: #fff;
      }
    }
    .qhtm {
      background-color: #fff;
      .el-row {
        text-align: center;
        padding: 10px 0;
        font-size: 16px;
        margin-bottom: 20px;
        .el-col {
          cursor: pointer;
          img {
            width: 20px;
            vertical-align: text-bottom;
          }
        }
      }
    }
  }
}
.immediately {
  background-color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
}
.subject_list {
  min-height: 250px;
  margin-bottom: 15px;
  background-color: #fff;
  ul {
    padding: 20px 30px;
    background-color: #fff;
    overflow: hidden;
    li {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      float: left;
      cursor: pointer;
      border: 1px solid #bbb;
      text-align: center;
      margin: 0 10px 10px 0;
      display: inline-block;
    }
    .bgc_active {
      background-color: #1288f4;
      border: 1px solid #1288f4;
      color: #fff;
    }
    .bgc_active1 {
      background-color: #cccccc;
      border: 1px solid #cccccc;
      color: #fff;
    }
  }
  .xs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    div {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid #bbb;
        margin-right: 5px;
      }
      &:nth-child(1) {
        i {
          background-color: #1288f4;
          border: 1px solid #1288f4;
        }
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
        i {
          background-color: #cccccc;
          border: 1px solid #cccccc;
        }
      }
    }
  }
}
.subject_cont {
  padding: 20px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    color: #333;
    font-weight: bold;
    i {
      color: #a4a4a4;
    }
  }
  ul {
    padding: 20px 0;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 20%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      span {
        cursor: pointer;
        display: inline-block;
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        border: 1px solid #bbb;
      }
    }
    .bgc_active {
      background-color: #cccccc;
      border: 1px solid #cccccc;
      color: #fff;
    }
    .bgc_active1 {
      background-color: #1288f4;
      border: 1px solid #1288f4;
      color: #fff;
    }
  }
  .xs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      margin-right: 20px;
      i {
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #bbb;
        margin-right: 12px;
      }
      &:nth-child(1) {
        i {
          background-color: #cccccc;
          border: 1px solid #cccccc;
        }
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
        i {
          background-color: #1288f4;
          border: 1px solid #1288f4;
        }
      }
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
