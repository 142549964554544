/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:17 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-11-Mo 11:53:06
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1"

// 课程列表
export async function courseQueryCourseList(params) {
  let res = await http.post(`${resquest}/Course/QueryCourseList`, params);
  return res.data;
}

// 教师列表
export async function baseQueryTeacherList(params) {
  let res = await http.post(`${resquest}/Base/QueryTeacherList`, params);
  return res.data;
}

// 教师详情
export async function queryTeacherInfo(params) {
  let res = await http.get(`${resquest}/Base/QueryTeacherInfo`, params);
  return res.data;
}

// banner
export async function commonQueryBannerList(params) {
  let res = await http.get(`${resquest}/Common/QueryBannerList?category=1`, params);
  return res.data;
}

// 获取百度人脸对比token
export async function getAccessToken(params) {
  let res = await http.get(`${resquest}/Student/GetAccessToken`, params);
  return res.data;
}

// 获取百度人脸对比token
export async function faceMatch(token, params) {
  let res = await http.post(`/baiducors/rest/2.0/face/v3/match?access_token=` + token, JSON.stringify(params), {
    showLoading: true
  }, true);
  return res.data;
}

// 获取资讯类别列表
export async function commonQueryNewsCategory(params) {
  let res = await http.get(`${resquest}/Common/QueryNewsCategory`);
  return res.data;
}

// 查询资讯列表
export async function commonQueryNewsList(params) {
  let res = await http.post(`${resquest}/Common/QueryNewsList`, params);
  return res.data;
}

// 获取资讯详情
export async function commonQueryNewsDetail(params) {
  let res = await http.get(`${resquest}/Common/QueryNewsDetail` + params);
  return res.data;
}

// 获取教师作品列表
export async function commonQueryTeacherProduce(params) {
  let res = await http.get(`${resquest}/Common/QueryTeacherProduce` + params);
  return res.data;
}

// 获取教师作品详情
export async function commonQueryTeacherProduceDetail(params) {
  let res = await http.get(`${resquest}/Common/QueryTeacherProduceDetail` + params);
  return res.data;
}

// 浏览量自动+1
export async function updateNewsReadCount(params) {
  let res = await http.post(`${resquest}/News/UpdateNewsReadCount` + params);
  return res.data;
}