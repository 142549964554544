/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:48:57 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-09 09:29:42
 */
import Vue from 'vue';
import {
    Calendar,
    Input,
    Select,
    Option,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Button,
    Row,
    Col,
    Progress,
    Upload,
    Message,
    Dialog,
    Pagination,
    Popover,
    Scrollbar,
    DatePicker,
    TimePicker,
    Table,
    TableColumn,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Radio,
    RadioGroup,
    RadioButton,
    MessageBox,
    Loading,
    Tree,
    Form,
    FormItem,
    Carousel,
    CarouselItem,
    Cascader,
    CascaderPanel,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tabs,
    TabPane,
    Tooltip,
    Collapse,
    CollapseItem,
    Image,
    Rate
} from 'element-ui';
//消息提示过多，重置message，防止重复点击重复弹出message弹框，下一个弹框显示时关闭上一个弹框
import {
    message
} from './resetMessage.js'
Vue.prototype.$message = message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Progress);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Scrollbar);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Loading);
Vue.use(Tree);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tooltip);
Vue.use(Calendar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Image);
Vue.use(Rate);
