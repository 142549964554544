/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:50:19 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-22 11:02:05
 */
// const hostUrl = "https://puliedu.1plus.store/";
const hostUrl = "https://api.pulijiaoyu.org.cn/";

export default {
  hostUrl
};