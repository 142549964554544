/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 18:12:06 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-Th 09:23:26
 */
<template>
  <div class="personal">
    <div class="personal_top">
      <div class="personal_top_cont width_1200">
        <div class="xx">
          <el-upload
            class="avatar-uploader"
            :limit="1"
            :headers="imgHeaders"
            :http-request="postImagesUpload"
            action=""
            :show-file-list="false"
          >
            <img v-if="user.HeadImg" v-lazy="Imghost + user.HeadImg" alt="" />
            <img v-else src="@/assets/images/avatar_default.png" alt="" />
          </el-upload>
          <div class="sm">
            <span v-if="user.NickName">{{ user.NickName }}</span>
            <span v-else>{{ user.Phone }}</span>
            <p class="c_EBA52C" v-if="user.Status === 0">
              <img src="@/assets/images/sm1.png" alt="" />已实名
            </p>
            <p class="c_CFCECE" v-else-if="user.Status === -1">
              <img src="@/assets/images/sm2.png" alt="" />未实名
            </p>
            <p class="c_CFCECE" v-else-if="user.Status === -99">
              <img src="@/assets/images/sm2.png" alt="" />审核失败
            </p>
            <p class="c_E72525" v-else-if="user.Status === 1">
              <img src="@/assets/images/sm3.png" alt="" />审核中
            </p>
          </div>
        </div>
        <ul>
          <li>
            <span>普利点</span>
            <span class="c_F61717" @click="goTo('/personal/puliPoint')">{{
              user.ECash == null ? "0" : user.ECash
            }}</span>
          </li>
          <li>
            <span>积分</span>
            <span @click="goTo('/PCshop/my/shopmy')">
              {{ user.Point == null ? "0" : user.Point }}
            </span>
          </li>
          <li>
            <span>优惠券</span>
            <span @click="goTo('/personal/coupon')">
              {{ user.CouponCount == null ? "0" : user.CouponCount }}
            </span>
          </li>
          <li>
            <span>消息</span>
            <span @click="goTo('/personal/news')">0</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="cont width_1200">
      <ul class="personal_left">
        <li
          v-for="(li, i) in list"
          :key="i"
          :class="listIndex == li.id ? 'active' : ''"
          @click="listClick(li)"
        >
          <img :src="listIndex == li.id ? li.img_check : li.img" alt="" />
          <span>{{ li.title }}</span>
        </li>
      </ul>
      <div class="personal_right">
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import camera from "@/components/canvasCamera.vue";
import { imagesUploadPic } from "@/api/api";
import {
  studentStudentInfoEdit,
} from "@/api/personal";
import { mapState } from "vuex";
export default {
  data () {
    return {
      imgHeaders: { "Content-Type": "multipart/form-data" },
      Imghost: this.GLOBAL.hostUrl,
      list: [
        {
          id: 1,
          title: "我的课程",
          img: require("../../assets/images/personal_img1.png"),
          img_check: require("../../assets/images/personal_img1_check.png"),
        },
        {
          id: 2,
          title: "我的课表",
          img: require("../../assets/images/personal_img2.png"),
          img_check: require("../../assets/images/personal_img2_check.png"),
        },
        {
          id: 7,
          title: "我的资料",
          img: require("../../assets/images/personal_img7.png"),
          img_check: require("../../assets/images/personal_img7_check.png"),
        },
        {
          id: 3,
          title: "我的作业",
          img: require("../../assets/images/personal_img3.png"),
          img_check: require("../../assets/images/personal_img3_check.png"),
        },
        {
          id: 9,
          title: "习题中心",
          img: require("../../assets/images/personal_img8.png"),
          img_check: require("../../assets/images/personal_img8_check.png"),
        },
        {
          id: 8,
          title: "我的练习",
          img: require("../../assets/images/personal_img9.png"),
          img_check: require("../../assets/images/personal_img9_check.png"),
        },
        {
          id: 4,
          title: "我的订单",
          img: require("../../assets/images/personal_img4.png"),
          img_check: require("../../assets/images/personal_img4_check.png"),
        },
        // {
        //   id: 5,
        //   title: "租聘圈",
        //   img: require("../../assets/images/personal_img5.png"),
        //   img_check: require("../../assets/images/personal_img5_check.png"),
        // },
        {
          id: 6,
          title: "个人资料",
          img: require("../../assets/images/personal_img6.png"),
          img_check: require("../../assets/images/personal_img6_check.png"),
        },
        {
          id: 10,
          title: "商城中心",
          img: require("../../assets/images/personal_img10.png"),
          img_check: require("../../assets/images/personal_img10_check.png"),
        },
      ],
      dialogVisible: false,
      jump: {},
      listIndex: 0,
      user: {},
      headImg: ""
    };
  },
  components: {
    camera,
  },
  created () { },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo (currVal) {
      // 监听mapState中的变量，当数据变化（有值、值改变等），
      // 保证能拿到完整的数据，不至于存在初始化没有数据的问题，然后可以赋给本组件data中的变量
      this.user = JSON.parse(currVal);
    },
    $route: "init",
  },
  mounted () {
    this.user = JSON.parse(this.$store.state.userInfo);
    this.init();
    this.common.users();
  },
  methods: {
    getClose () {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace (flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        localStorage.setItem("isShowFace", 0);
        // this.getQuestionBankQueryQuestionCourse();
        this.$router.push({ path: this.jump.url });
        this.dialogVisible = false;
      } else {
        this.$message.error("人脸识别失败，请刷新后重试");
        this.dialogVisible = false;
      }
    },
    async postImagesUpload (param) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(param.file);
      formData.append("file", newFile);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        this.headImg = res.response
        this.getStudentStudentInfoEdit()
      } else {
        this.$message.error(res.msg);
      }
    },
    //修改信息
    async getStudentStudentInfoEdit () {
      let parm = {
        EmergencyContact: this.user.emergencyContact,
        EmergencyPhone: this.user.emergencyPhone,
        MailAddress: this.user.mailAddress,
        ExamDirection: this.user.examDirection,
        ExamSchool: this.user.examSchool,
        ExamMajor: this.user.examMajor,
        TrainCourse: this.user.trainCourse,
        HeadImg: this.headImg,
        NickName: this.user.NickName,
      };
      const res = await studentStudentInfoEdit(parm);
      if (res.success == true) {
        this.$message.success(res.msg);
        this.common.users();
      } else {
        this.$message.error(res.msg);
      }
    },
    goTo (url) {
      this.$router.push({ path: url });
    },
    init () {
      console.log(this.$route.path)
      if (this.$route.path === "/personal/myCourse") {
        this.listIndex = 1;
      } else if (this.$route.path === "/personal/myTimetable") {
        this.listIndex = 2;
      } else if (this.$route.path == "/personal/myTask") {
        this.listIndex = 3;
      } else if (this.$route.path == "/personal/myOrder") {
        this.listIndex = 4;
      } else if (this.$route.path === "/personal/personalData") {
        this.listIndex = 6;
      } else if (this.$route.path === "/personal/myData") {
        this.listIndex = 7;
      } else if (this.$route.path === "/personal/myPractice/practice" || this.$route.path.indexOf("/personal/myPractice") > -1) {
        this.listIndex = 8;
      } else if (this.$route.path === "/personal/questionCenter") {
        this.listIndex = 9;
      } else {
        this.listIndex = 0;
      }
    },
    listClick (obj) {
      this.listIndex = obj.id;
      if (obj.id == 1) {
        this.$router.push("/personal/myCourse");
      } else if (obj.id == 2) {
        this.$router.push("/personal/myTimetable");
      } else if (obj.id == 3) {
        this.$router.push("/personal/myTask");
      } else if (obj.id == 4) {
        this.$router.push("/personal/myOrder");
      } else if (obj.id == 5) {
      } else if (obj.id == 6) {
        this.$router.push("/personal/personalData");
      } else if (obj.id == 7) {
         localStorage.setItem("isShowFace", 1);
        this.dialogVisible = true;
        this.jump.url = "/personal/myData";
        // this.$router.push("/personal/myData");
      } else if (obj.id == 8) {
        this.route = this.$route.query
        if (this.route.CourseId != undefined) {
          this.$router.push("/personal/myPractice/practice?CourseId=" + this.route.CourseId);
        } else {
          this.$router.push("/personal/myPractice/practice");
        }
      } else if (obj.id == 9) {
        localStorage.setItem("isShowFace", 1);
        this.dialogVisible = true;
        this.jump.url = "/personal/questionCenter";
        // this.$router.push("/personal/questionCenter");
      } else if (obj.id == 10) {
        this.$router.push("/PCshop/my/shopmy");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.personal {
  .personal_top {
    // width: 100%;
    min-width: 1200px;
    height: 240px;
    background-size: 100% 100%;
    // margin-top: -30px;
    background-image: url("../../assets/images/personal_banner_bg.png");
    .personal_top_cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      height: 100%;
      color: #ffffff;
      .xx {
        display: flex;
        align-items: center;
        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin-right: 30px;
        }

        .sm {
          span {
            line-height: 100%;
            margin-bottom: 20px;
          }
          p {
            line-height: 18px;
            font-size: 14px;
            margin-bottom: 10px;
            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
              margin-right: 10px;
            }
          }
        }
      }
      ul {
        display: flex;
        li {
          span {
            line-height: 100%;
            display: block;
            text-align: center;
            margin: 0 25px;
            cursor: pointer;
            &:last-child {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  .cont {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    .personal_left {
      width: 200px;
      li {
        cursor: pointer;
        height: 64px;
        display: flex;
        align-items: center;
        background-color: #fff;
        img {
          width: 20px;
          height: 20px;
          margin: 0 20px 0 45px;
        }
      }
      .active {
        color: #fff;
        background-color: #1288f4;
      }
    }
    .personal_right {
      width: 970px;
      background-color: #fff;
    }
  }
}
</style>
