/*
 * @Author: liuzhixiang
 * @Date: 2021-02-06 14:30:01
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-10-Sa 02:43:11
 */
import Vue from "vue";

import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
import $ from "jquery";
Vue.use(Vuex);

import "swiper/swiper-bundle.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
// 延迟加载,图片出错
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./assets/images/error.jpg"),
  loading: require("./assets/images/error.jpg"),
  attempt: 1,
});

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 瀑布流
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

import router from "./router";
import App from "./App.vue";
import store from "./store";
import "./assets/css/global.less"; /*引入公共样式*/

// 按需加载 element UI
import "element-ui/lib/theme-chalk/index.css";
import "./utils/element";

// 复制
import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);

import utils from "@/utils/index";
Vue.prototype.GLOBAL = utils;

import common from "@/utils/common.js";
Vue.prototype.common = common;
Object.keys(common).forEach((key) => {
  Vue.filter(key, common[key]);
});
Vue.config.productionTip = false;

// import { Tabbar, TabbarItem ,Form ,Field,Button,NavBar  } from 'vant';

import {
  VueJsonp
} from "vue-jsonp";
Vue.use(VueJsonp);
// Vue.use(Button);
// Vue.use(Form);
// Vue.use(Field);
// Vue.use(Tabbar);
// Vue.use(TabbarItem);
// Vue.use(NavBar);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");