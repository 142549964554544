/* * @Author: liuzhixiang * @Date: 2021-02-06 15:18:15 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-08 14:15:05 */
<template>
  <div class="topicPaper">
    <div class="cont">
      <div class="top" v-if="mobile != true">
        <div class="title" @click="quitPractice()">
          <i class="el-icon-arrow-left"></i>
          退出练习
        </div>
      </div>
      <div class="list" v-if="recitationMode">
        <div class="li">
          <div class="top1">
            <div class="title">
              {{ index }}、{{ recitationMode.Name }}
              <i
                @click="play()"
                class="play"
                v-if="recitationMode.Category == 0"
              ></i>
            </div>
            <div class="correction" @click="correction">
              纠错 <i class="el-icon-edit-outline"></i>
            </div>
          </div>
          <img
            class="extend"
            v-lazy="recitationMode.Extend"
            v-if="recitationMode.Extend != null && recitationMode.Extend != ''"
            alt=""
          />
          <el-button size="mini" v-if="parsingShow" @click="parsClick()"
            >解析</el-button
          >
          <div class="pars" v-html="recitationMode.Explain" v-else></div>
        </div>
      </div>
      <div class="list" v-if="recitationMode == undefined">
        <div class="li">
          <no-data></no-data>
        </div>
        <div class="restart">
          <el-button
            v-if="response.pageCount > 0 && response.data.length == 0"
            @click="restartPractice()"
            >重新开始</el-button
          >
        </div>
      </div>
      <div v-if="recitationMode" class="answer">
        <div v-for="(an, i) in answer" :key="i" @click="answerClick(an, i)">
          {{ an }}
        </div>
      </div>
      <!-- <el-row v-if="recitationMode">
        <el-col
          :span="8"
          v-for="(an, i) in answer"
          :key="i"
          @click.native="answerClick(an, i)"
        >
          {{ an }}
        </el-col>
      </el-row> -->
    </div>
    <audio ref="audio"></audio>
    <!--纠错弹窗-->
    <dialog-Visible
      v-if="dialogVisible != false"
      type="2"
      :width="width"
      :dialogVisible="dialogVisible"
      :obj="correctionArr"
      @find="getFind"
      @close="getClose()"
    ></dialog-Visible>
  </div>
</template>

<script>
import {
  questionBankQueryWordsList,
  questionBankSaveWordsRecord,
  questionBankCorrectionQuestion
} from "@/api/questionBank";
import dialogVisible from "@/components/components/dialogVisible.vue";
import noData from "@/components/noData";
export default {
  data () {
    return {
      recitationMode: {},
      index: 0,
      answer: ["熟悉", "不熟悉", "陌生"],
      question: {},
      radio: "",
      parsingShow: true,
      lastNum: 0,
      wordsCategory: 0,
      user: {},
      route: {},
      mobile: false,
      restart: true, //true：重新开始，false：
      again: 0, //重新开始
      response: {},
      dialogVisible: false,
      width: "",
      correctionArr: {},
      wordId: 0
    };
  },
  components: {
    dialogVisible, noData
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.route = this.$route.query;
    let sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      //跳转移动端页面
      this.mobile = true;
    } else {
      //跳转pc端页面
      this.mobile = false;
    }
    this.wordId = this.route.WordId;
    this.init();
  },
  mounted () { },
  methods: {
    async getFind (e) {
      // console.log(e);
      const res = await questionBankCorrectionQuestion(e);
      if (res.success === true) {
        this.$message.success("提交成功");
        this.dialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 弹窗关闭
    getClose () {
      this.dialogVisible = false;
    },
    //纠错
    correction () {
      this.correctionArr = { Id: this.recitationMode.Id, Name: this.recitationMode.Name }
      this.dialogVisible = true;
      if (this.mobile == true) {
        this.width = "85%";
      } else {
        this.width = "400px";
      }
    },
    restartPractice () {
      this.again = 1;
      this.index = 0;
      this.lastNum = 0;
      this.init();
    },
    //播放词语
    play () {
      this.$refs.audio.src =
        "https://dict.youdao.com/speech?audio=" + this.recitationMode.Name;
      this.$refs.audio.play();
    },
    async answerClick (an, i) {
      let parm = {
        courseId: this.question.CourseId,
        studentId: this.route.UserID,
        ChapterId: this.route.ChapterId,
        wordId: this.lastNum,
        answer: i,
        Category: this.wordsCategory
      };
      const res = await questionBankSaveWordsRecord(parm);
      if (res.success === true) {
        this.wordId = 0;
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    parsClick () {
      this.parsingShow = false;
    },
    handChange () { },
    //退出练习
    quitPractice () {
      this.$router.go(-1);
    },
    async init () {
      this.question = this.$route.query;
      let parm = {
        CourseId: this.question.CourseId,
        chapterId: this.question.ChapterId,
        Category: this.question.questionCategory,
        StudentId: this.route.UserID,
        LastNum: this.lastNum,
        pageIndex: 1,
        pageSize: 1,
        Answer: this.route.Answer,
        WordId: this.wordId,
      };
      if (this.again == 1) {
        parm = { ...parm, ...{ Restart: this.restart } };
      }
      const res = await questionBankQueryWordsList(parm);
      if (res.success === true) {
        this.index++;
        this.response = res.response;
        this.recitationMode = res.response.data[0];
        this.again = 0;
        this.parsingShow = true;
        this.lastNum = res.response.data[0]?.Id;
        this.wordsCategory = res.response.data[0]?.Category;
      } else {
        this.recitationMode = undefined;
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.topicPaper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      .title {
        line-height: 100%;
        padding: 20px 0;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        i {
          font-weight: bold;
        }
      }
    }
    .list {
      min-height: 350px;
      margin-bottom: 15px;
      background-color: #fff;
      .restart {
        text-align: center;
        margin: 100px 0;
      }
      .li {
        margin-bottom: 5px;
        padding: 20px 30px;
        background-color: #fff;
        img {
          width: 100%;
        }
        .extend {
          margin-bottom: 20px;
        }
        .pars {
          /deep/img {
            width: 100%;
          }
        }
        .top1 {
          display: flex;
          justify-content: space-between;
          .correction {
            margin-left: 5px;
            color: #c5c5c5;
            font-size: 12px;
            width: 40px;
            cursor: pointer;
          }
        }
        .title {
          flex: 1;
          margin-bottom: 20px;
          .play {
            display: inline-block;
            width: 15px;
            height: 15px;
            vertical-align: middle;
            margin-left: 15px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("../assets/images/audio.png");
          }
          p {
            line-height: 22px;
            height: 22px;
          }
          .tmtype {
            span {
              &:first-child {
                display: inline-block;
                background-color: #56b155;
                color: #fff;
                padding: 0 8px;
                border-radius: 20px;
                margin-right: 20px;
              }
            }
          }
          .tmmc {
            margin-top: 10px;
          }
        }
        .el-radio-group {
          p {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .el-row {
      background-color: #fff;
      text-align: center;
      padding: 10px 0;
      font-size: 16px;
      margin-bottom: 20px;
      .el-col {
        cursor: pointer;
        img {
          width: 20px;
          vertical-align: text-bottom;
        }
      }
    }
  }
}
.answer {
  display: flex;
  margin-bottom: 30px;
  div {
    width: 160px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 40px;
    background-color: #ffffff;
    border-radius: 30px;
    &:hover {
      background-color: #558af9;
      color: #fff;
    }
  }
}
</style>
