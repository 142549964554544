/* * @Author: liuzhixiang * @Date: 2021-02-06 14:39:22 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-08 11:21:28 */
<template>
  <div>
    <!-- ##### Header Area Start ##### -->
    <!-- <div id="preloader">
        <div class="spinner"></div>
    </div>-->
    <header class="header-area">
      <!-- Navbar Area -->
      <div
        ref="nav"
        class="clever-main-menu"
        :class="scrollActive == 0 ? '' : 'clever-main-menu-active'"
        id="nav"
      >
        <div class="classy-nav-container breakpoint-off">
          <!-- Menu -->
          <nav class="classy-navbar justify-content-between" id="cleverNav">
            <!-- Logo -->
            <a class="nav-brand" href="index.html"
              ><img src="../assets/images/logo.png" alt="" width="175"
            /></a>
            <!-- Navbar Toggler -->
            <div class="classy-navbar-toggler">
              <span class="navbarToggler"
                ><span></span><span></span><span></span
              ></span>
            </div>
            <!-- Menu -->
            <div class="classy-menu">
              <!-- Close Button -->
              <div class="classycloseIcon">
                <div class="cross-wrap">
                  <span class="top"></span><span class="bottom"></span>
                </div>
              </div>
              <!-- Nav Start -->
              <div class="classynav">
                <ul>
                  <li
                    class="he_col"
                    @click="activeClick(rou)"
                    :class="activeIndex == rou.id ? 'active' : ''"
                    v-for="(rou, i) in route"
                    :key="i"
                  >
                    <router-link :to="rou.url">{{ rou.name }}</router-link>
                  </li>
                </ul>
                <!-- Search Button -->
                <div class="search-area">
                    <form action="#" method="post">
                    <input
                      type="search"
                      name="search"
                      id="search"
                      placeholder="Search"
                      style="display: none"
                    />
                    <!-- <button type="submit">
                      <i
                        class="fa fa-search"
                        aria-hidden="true"
                        style="display: none"
                      ></i>
                    </button> -->
                  </form>
                </div>
                <!-- Register / Login -->
                <div class="register-login-area" v-if="user.Id == undefined">
                  <a href="#" class="btn" @click="goTo('/register')">注册</a>
                  <a href="#" @click="goTo('/login')" class="btn active"
                    >登录</a
                  >
                </div>
                <div v-else class="login-state d-flex align-items-center">
                  <div
                    class="user-name mr-30"
                    @mouseenter="userShowMouseenter()"
                    @mouseleave="userShowMouseleave()"
                  >
                    <div class="dropdown" style="cursor: pointer">
                      <!-- @click="userShowClick()" -->
                      <a class="dropdown-toggle" v-if="user.RealName">{{
                        user.RealName
                      }}</a>
                      <a
                        class="dropdown-toggle"
                        @mouseenter="userShowMouseenter()"
                        @mouseleave="userShowMouseleave()"
                        v-else
                        >{{ user.Phone }}</a
                      >
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        style="top: 40px"
                        @mouseenter="userShowMouseenter()"
                        @mouseleave="userShowMouseleave()"
                      >
                        <!-- v-if="userShow" -->
                        <a
                          class="dropdown-item"
                          @click="goTo('/personal/myCourse')"
                          >个人中心</a
                        >
                        <a
                          class="dropdown-item"
                          @click="goTo('/shopping/shoppingCart')"
                          >购课车</a
                        >
                        <a class="dropdown-item" @click="quitClick()"
                          >退出登录</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="userthumb">
                    <img
                      v-if="user.HeadImg"
                      v-lazy="Imghost + user.HeadImg"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/avatar_default.png"
                      alt=""
                    />
                  </div>
                </div>
                <!-- <el-popover
                  v-else
                  placement="bottom"
                  width="150"
                  trigger="hover"
                >
                  <div class="tuic">
                    <span @click="goTo('/personal/myCourse')">
                      <i class="el-icon-user"></i> 个人中心
                    </span>
                    <span @click="goTo('/shopping/shoppingCart')">
                      <i class="el-icon-shopping-cart-2"></i> 购课车
                    </span>
                    <span @click="quitClick()">
                      <i class="el-icon-switch-button"></i> 退出登录
                    </span>
                  </div>
                  <div class="dlcg" slot="reference">
                    <img
                      v-if="user.FaceImg"
                      v-lazy="Imghost + user.FaceImg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/images/avatar_default.png"
                      alt=""
                    />
                    <span v-if="user.RealName">{{ user.RealName }}</span>
                    <span v-else>{{ user.Phone }}</span>
                  </div>
                </el-popover> -->
              </div>
              <!-- Nav End -->
            </div>
          </nav>
        </div>
      </div>
    </header>
    <div style="height: 80px"></div>
    <!-- ##### Header Area End ##### -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data () {
    return {
      Imghost: this.GLOBAL.hostUrl,
      activeIndex: 0,
      route: [
        {
          id: 1,
          url: "/home",
          name: "首页",
        },
        {
          id: 2,
          url: "/course/index",
          name: "精选课程",
        },
        {
          id: 3,
          url: "/dataDownload/index",
          name: "资料下载",
        },
        {
          id: 4,
          url: "/onlineQuestionBank/index",
          name: "在线题库",
        },
        {
          id: 5,
          url: "/PCshop/PChome",
          name: "在线商城",
        },
        {
          id: 6,
          url: "/art/index",
          name: "在线艺术",
        },
        {
          id: 7,
          url: "/bbs",
          name: "艺术交流",
        },
        {
          id: 8,
          url: "/fineArts",
          name: "三合美术馆",
        },
      ],
      user: {},
      scrollActive: 0,
      userShow: false,
    };
  },
  created () { },
  mounted () {
    this.init();
    window.addEventListener("scroll", this.handleScroll, true);
    if (localStorage.getItem("userInfo")) {
      this.user = JSON.parse(this.$store.state.userInfo);
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo (currVal) {
      // 监听mapState中的变量，当数据变化（有值、值改变等），
      // 保证能拿到完整的数据，不至于存在初始化没有数据的问题，然后可以赋给本组件data中的变量
      this.user = JSON.parse(currVal);
    },
    $route: "init",
  },
  methods: {
    ...mapActions(["saveUserInfo"]),
    quitClick () {
      this.saveUserInfo(JSON.stringify({}));
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("teacherInfo");
      localStorage.removeItem("artInfo");
      localStorage.removeItem("artUserId");
      this.$router.push({ path: "/login" });
    },
    userShowMouseenter () {
      this.userShow = true;
    },
    userShowMouseleave () {
      // setTimeout(() => {
      this.userShow = false;
      // }, 2000);
    },
    userShowClick () {
      if (this.userShow == false) {
        this.userShow = true;
      } else {
        this.userShow = false;
      }
      console.log(this.userShow);
    },
    // 距离顶部距离
    handleScroll () {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 0 && scrollTop < 1) {
        this.scrollActive = 0;
      } else {
        this.scrollActive = 1;
      }
    },
    init () {
      let route = this.$route.path.split("/")[1];
      if (route === "home") {
        this.activeIndex = 1;
      } else if (route === "course") {
        this.activeIndex = 2;
      } else if (route === "dataDownload") {
        this.activeIndex = 3;
      } else if (route === "onlineQuestionBank") {
        this.activeIndex = 4;
      } else if (route === "bbs") {
        this.activeIndex = 7;
      } else if (route === "fineArts") {
        this.activeIndex = 8;
      } else {
        this.activeIndex = 0;
      }
    },
    goTo (url) {
      this.userShow = false;
      this.$router.push({ path: url });
    },
    activeClick (rou) {
      this.activeIndex = rou.id;
    },
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/style.css");
// @import url("../assets/css/classy-nav.min.css");
p {
  margin: 0;
}
.tuic {
  span {
    display: block;
    cursor: pointer;
    line-height: 28px;
  }
}
.active {
  a {
    color: #3a78f9 !important;
  }
}

.clever-main-menu {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 80px;
  .classy-navbar {
    height: 80px;
  }
  .classynav ul li a {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      padding: 0 8px;
    }
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .search-area {
    position: relative;
    z-index: 1;
    form {
      position: relative;
      z-index: 10;
      width: 230px;
      height: 40px;
      margin: 0 50px;
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 170px;
        margin: 0 15px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin: 15px auto;
      }
      @media only screen and (max-width: 767px) {
        margin: 15px auto;
      }
      input {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #ebebeb;
        padding: 0 15px 0 50px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.35);
        font-weight: 600;
        @include trans-duration(500ms);
        &:focus {
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
      button {
        position: absolute;
        width: 50px;
        height: 40px;
        background-color: transparent;
        top: 0;
        left: 0;
        z-index: 10;
        border: none;
        text-align: center;
        cursor: pointer;
        outline: none;
      }
    }
  }
}

.clever-main-menu-active {
  position: fixed;
  top: 0px;
  z-index: 1500 !important;
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.userthumb {
  flex: 0 0 40px;
  max-width: 40px;
  width: 40px;
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

.dlcg {
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
}
.classynav ul li a {
  font-size: 15px;
}
.dropdown {
  width: 150px;
  text-align: right;
}
.dropdown-menu {
  display: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-toggle {
  height: 53px;
  line-height: 53px;
}
</style>
