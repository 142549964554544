<template>
  <div id="app">
    <keep-alive>
      <!-- 这里是会被缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!-- 这里是不被缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
  data () {
    return {
    };
  },
  watch: {

  },
};
</script>

<style>
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

p {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.textOver {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.clearance {
  width: 100%;
  height: 20px;
  background: #f5f5f5;
}
.van-tabs__line{
  background: #000;
}

</style>
