/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 09:50:14 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 16:16:34
 */
import {
  commonQueryUserInfo
} from "@/api/personal";
import store from "../store";
export default {
  async users() {
    let res = await commonQueryUserInfo();
    if (res.success != true) {
      return false
    }
    let data = res.response;
    // let Authorization = JSON.parse(store.state.userInfo).Authorization;
    let Authorization = localStorage.getItem("token");
    data.Authorization = Authorization;
    store.commit('saveUserInfo', JSON.stringify(data));
  },
  getRandom(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  },
  // 保留两位小数
  priceToFixed(val) {
    return Number(val).toFixed(2);
  },
  // 年月日
  dataYMD(time) {
    if (time == null) {
      return "";
    }
    let data;
    if (time.constructor != String) {
      data = new Date(time);
    } else {
      data = new Date(time.replace(/\-/g, "/"));
    }
    let y = data.getFullYear();
    let m = (data.getMonth() + 1).toString().padStart(2, 0);
    let d = data.getDate().toString().padStart(2, 0);
    return y + "-" + m + "-" + d;
  },
  // 年月日时分秒
  dataYMDhms(time) {
    if (time == null) {
      return "";
    }
    let data;
    if (time.constructor != String) {
      data = new Date(time);
    } else {
      data = new Date(time.replace(/\-/g, "/"));
    }
    let Y = data.getFullYear();
    let M = (data.getMonth() + 1).toString().padStart(2, 0);
    let D = data.getDate().toString().padStart(2, 0);
    let h = data.getHours().toString().padStart(2, 0);
    let m = data.getMinutes().toString().padStart(2, 0);
    let s = data.getSeconds().toString().padStart(2, 0);
    return Y + "-" + M + "-" + D + ' ' + h + ':' + m + ':' + s;
  },
  // 时分秒
  hms(time) {
    if (time == null) {
      return "";
    }
    let data;
    if (time.constructor != String) {
      data = new Date(time);
    } else {
      data = new Date(time.replace(/\-/g, "/"));
    }
    let Y = data.getFullYear();
    let M = (data.getMonth() + 1).toString().padStart(2, 0);
    let D = data.getDate().toString().padStart(2, 0);
    let h = data.getHours().toString().padStart(2, 0);
    let m = data.getMinutes().toString().padStart(2, 0);
    let s = data.getSeconds().toString().padStart(2, 0);
    return h + ':' + m + ':' + s;
  },
  // 获取当天时间戳
  getTodayTimestamp() {
    var today = new Date();
    var month = today.getMonth() + 1;
    month = (month < 10 ? "0" + month : month);
    var tdstr = (today.getFullYear() + "/" + month + "/" + today.getDate());
    var date = new Date(Date.parse(tdstr));
    var tdtsp = Number(date);
    return tdtsp;
  },
  // 时间戳 格式化为 时分秒（00:00:00）
  sec_to_time(time) {
    var t;
    if (time > -1) {
      var hour = Math.floor(time / 3600);
      var min = Math.floor(time / 60) % 60;
      var sec = time % 60;
      if (hour < 10) {
        t = '0' + hour + ":";
      } else {
        t = hour + ":";
      }

      if (min < 10) {
        t += "0";
      }
      t += min + ":";
      if (sec < 10) {
        t += "0";
      }
      t += sec.toFixed(0);
    }
    return t;
  },
  // 移动端、PC端判断
  _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  },
  //计时器
  timeCounting(bolean) {
    let _this = this
    let hour, minute, second
    hour = minute = second = 0
    if (bolean === true) {
      _this.timer = setInterval(function () {
        if (second >= 0) {
          second = second + 1
        }
        if (second >= 60) {
          second = 0
          minute = minute + 1
        }
        if (minute >= 60) {
          minute = 0
          hour = hour + 1
        }
        _this.useTime = hour + '时' + minute + '分' + second + '秒'
        console.log(_this.useTime);
      }, 1000)
    } else {
      window.clearInterval(_this.timer);
      return _this.useTime;
    }
  },
  //base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
  dataURLtoFile(dataurl, fileName) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {
      type: mime
    });
  },
  //压缩图片
  compressImg(file) {
    var that = this;
    var src;
    var files;
    var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
    var fileName = file.name;
    console.log(fileName);
    var read = new FileReader();
    read.readAsDataURL(file);
    return new Promise(function (resolve, reject) {
      read.onload = function (e) {
        var img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          //默认按比例压缩
          var w = this.width,
            h = this.height;
          //生成canvas
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          var base64;
          // 创建属性节点
          canvas.setAttribute("width", w);
          canvas.setAttribute("height", h);
          ctx.drawImage(this, 0, 0, w, h);
          // base64 = canvas.toDataURL(file['type'], 0.1);
          if (fileSize < 0.2) {
            //如果图片小于一兆 那么不执行压缩操作
            base64 = canvas.toDataURL("image/jpeg", 1);
          } else if (fileSize > 0.2 && fileSize < 1) {
            //如果图片大于1M并且小于2M 那么压缩0.5
            base64 = canvas.toDataURL("image/jpeg", 0.5);
          } else {
            //如果图片超过2m 那么压缩0.2
            base64 = canvas.toDataURL("image/jpeg", 0.2);
          }
          // 回调函数返回file的值（将base64编码转成file）
          files = that.dataURLtoFile(base64, fileName); //如果后台接收类型为base64的话这一步可以省略

          resolve(files)
        };
      };
    })
  },
  // 获取富文本图片地址
  getImgSrc(richtext) {
    let imgList = [];
    richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
      imgList.push(capture);
    });
    return imgList;
  }
}