/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:20:12 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-03-04 16:18:51
 */
<template>
  <div class="login">
    <div class="cont">
      <img class="l_left" src="../../assets/images/login_img.png" alt="" />
      <div class="l_right">
        <h3>账号密码登录</h3>
        <div class="bk">
          <el-input placeholder="请输入手机号码" type="type" v-model="name">
            <img
              slot="prefix"
              width="25"
              src="../../assets/images/name.png"
              alt=""
            />
          </el-input>
          <el-input placeholder="请输入密码" type="password" v-model="passWord">
            <img
              slot="prefix"
              width="25"
              src="../../assets/images/pass.png"
              alt=""
            />
          </el-input>
          <div class="jz">
            <el-checkbox v-model="checked">请记住密码</el-checkbox>
            <div class="wj">
              <span
                @click="goTo('/forgetPassword')"
              >忘记密码</span>
                &nbsp;&nbsp;|&nbsp;&nbsp; 
                <span
                @click="goTo('/register')"
                >立即注册</span
              >
            </div>
          </div>
          <el-button type="primary" @click="postCommonLogin()"
            >立即登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { commonLogin } from "@/api/login";
export default {
  data() {
    return {
      name: "",
      passWord: "",
      checked: false,
    };
  },
  created() {},
  mounted() {
       localStorage.removeItem("token");
  },
  methods: {
    ...mapActions(['saveUserInfo']),
    goTo(url) {
      this.$router.push({ path: url });
    },
    async postCommonLogin() {
      if (this.name == "") {
        this.$message.info("请输入用户名");
        return false;
      }
      if (this.passWord == "") {
        this.$message.info("请输入密码");
        return false;
      }
      let parm = {
        pwd: this.passWord,
        userName: this.name,
      };
      const res = await commonLogin(parm);
      if (res.success == true) {
        localStorage.setItem("token", res.response.Authorization);
        if(res.response.FaceImg)
        {
          console.log(this.GLOBAL.hostUrl +res.response.FaceImg);
            this.imageUrlToBase64(this.GLOBAL.hostUrl +res.response.FaceImg)
        }
        this.saveUserInfo(JSON.stringify(res.response))
        this.$router.push("/personal/myCourse");
      } else {
        this.$message.error(res.msg);
      }
    },
        		//异步执行
		imageUrlToBase64(imageUrl) {
      console.log(imageUrl);
			//一定要设置为let，不然图片不显示
			let image = new Image();
			//解决跨域问题
			image.setAttribute('crossOrigin', 'anonymous');
      // image.crossOrigin = 'Anonymous';//外网访问必须加否则会报错
			// let imageUrl = "http://bigf.cqugeo.cn/landslip/res/defaultImages/default.png";
			image.src = imageUrl+ "?" + Math.random();
			//image.onload为异步加载
			image.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, image.width, image.height);
				
				var quality = 0.8;
				//这里的dataurl就是base64类型
				 let faceImg = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        localStorage.setItem("faceImgBase64",faceImg);
			}
		},
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/login_bg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .cont {
    background-color: #fff;
    width: 1200px;
    height: 600px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    .l_left {
      width: 600px;
      height: 600px;
    }
    .l_right {
      width: 600px;
      text-align: center;
      h3 {
        color: #1288f4;
        line-height: 100%;
        font-size: 30px;
        margin: 86px 0 60px;
        font-weight: normal;
      }
      .bk {
        width: 450px;
        margin: 0 auto;
        .el-input {
          margin-bottom: 30px;
          font-size: 20px;
          color: #999999;
          /deep/ input {
            height: 60px;
            padding-left: 70px;
          }
          /deep/ .el-input__prefix {
            img {
              margin: 15px 0 0 28px;
            }
          }
        }
        .jz {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          line-height: 100%;
          margin-bottom: 52px;
          .wj {
            cursor: pointer;
            color: #999999;
          }
        }
        .el-button {
          width: 100%;
          height: 60px;
          font-size: 24px;
          background-color: #1288f4;
        }
      }
    }
  }
}
</style>