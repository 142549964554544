// 暴露给用户使用，借此触发mutations中的方法，保存数据（可执行异步操作）
export const saveUserInfo = ({
  commit
}, payload) => {
  commit('saveUserInfo', payload);
};

export const setIsAdd = (context, value) => {
  context.commit('addItem', value)
};

export const search = (context, value) => {
  context.commit('search', value)
};

export const setSearchList = (context, value) => {
  context.commit('setSearchList', value)
};


export const setWsMsg = (context, value) => {
  context.commit('setWsMsg', value)
};

export const setWs = (context, value) => {
  context.commit('setWs', value)
};

export const setIdCollection = (context, value) => {
  context.commit('setIdCollection', value)
  console.log(context, '')
}