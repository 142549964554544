/*
 * @Author: liuzhixiang
 * @Date: 2021-02-06 15:29:29
 * @Last Modified by:   liuzhixiang
 * @Last Modified time: 2021-02-06 15:29:29
 */
import http from "./http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";

// 注册
export async function commonRegister(params) {
    let res = await http.post(`${resquest}/Common/Register`, params);
    return res.data;
}

// 账号密码登录
export async function commonLogin(params) {
    let res = await http.post(`${resquest}/Common/Login`, params);
    return res.data;
}

// 账号密码登录
export async function studentStudentEditPassword(params) {
    let res = await http.post(`${resquest}/Student/StudentEditPassword`, params);
    return res.data;
}