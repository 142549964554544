/* * @Author: liuzhixiang * @Date: 2021-02-08 12:05:46 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-08 17:18:41 */
<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="答题结果"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="list">
      <el-row type="flex" align="middle" v-if="mobile == 'PC'">
        <el-col :span="8">
          <p>本次练习共{{ results.AllQuestionCount }}道题目</p>
          <p>
            答错{{
              results.AllQuestionCount - results.CorrectQuestionCount
            }}道题
          </p>
          <p>交卷时间：{{ results.AnsertTime }}</p>
        </el-col>
        <el-col :span="8">
          <el-progress
            @click.native="seeDetails(mobile)"
            :width="250"
            type="circle"
            :percentage="
              (results.CorrectQuestionCount / results.AllQuestionCount) * 100
            "
            :format="format"
            :color="colorProgress"
          ></el-progress>
        </el-col>
        <el-col :span="8">
          <p>
            正确率：{{
              Math.ceil(
                (results.CorrectQuestionCount / results.AllQuestionCount) * 100
              )
            }}%
          </p>
          <p>考试时长：{{ results.useTime }}</p>
          <div>
            <el-button type="primary" @click="seeDetails(mobile)" size="mini"
              >查看详情</el-button
            >
          </div>
          <!-- <p>分数排名：</p> -->
        </el-col>
      </el-row>
      <el-row v-else class="App">
        <el-col :span="24">
          <p>本次练习共{{ results.AllQuestionCount }}道题目</p>
          <p>
            答错{{
              results.AllQuestionCount - results.CorrectQuestionCount
            }}道题
          </p>
          <p>交卷时间：{{ results.AnsertTime }}</p>
        </el-col>
        <el-col :span="24">
          <el-progress
            @click.native="seeDetails(mobile)"
            :width="250"
            type="circle"
            :percentage="
              (results.CorrectQuestionCount / results.AllQuestionCount) * 100
            "
            :format="format"
            :color="colorProgress"
          ></el-progress>
        </el-col>
        <el-col :span="24">
          <p>
            正确率：{{
              Math.ceil(
                (results.CorrectQuestionCount / results.AllQuestionCount) * 100
              )
            }}%
          </p>
          <p>考试时长：{{ results.useTime }}</p>
          <div>
            <el-button type="primary" @click="seeDetails(mobile)" size="mini"
              >查看详情</el-button
            >
          </div>
          <!-- <p>分数排名：</p> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      results: {},
      radio: "",
      colorProgress: "#E72525",
      mobile: "APP",
      jump: {},
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "答题结果";
      this.isWxApplets = false;
    }
    this.init();
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    format(percentage) {
      return Math.ceil(percentage) + "%";
    },
    seeDetails(val) {
      if (val == "PC" && this.results.type == 1) {
        this.jump.url = "/onlineQuestionBank/examRecordDetails";
      } else if (val == "PC" && this.results.type == 2) {
        this.jump.url = "/onlineQuestionBank/practiceRecordDetails";
      } else if (val == "APP" && this.results.type == 1) {
        this.jump.url = "/H5/examRecordDetails";
      } else if (val == "APP" && this.results.type == 2) {
        this.jump.url = "/H5/practiceRecordDetails";
      }
      let ob = {
        DataId: this.results.DataId,
      };
      this.jump.ob = ob;
      console.log(this.results);
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
    },
    init() {
      this.results = this.$route.query;
      console.log(this.results);
      // 移动端、PC判断
      if (this.common._isMobile()) {
        this.mobile = "APP";
      } else {
        this.mobile = "PC";
        console.log("tag", "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  padding: 20px 30px;
  min-height: 350px;
  background-color: #fff;
}
.el-row {
  background-color: #fff;
  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 20px;
  .el-col {
    cursor: pointer;
    p {
      line-height: 175%;
      margin-bottom: 10px;
    }
    &:first-child {
      text-indent: 35%;
      // margin-right: 75px;
    }
    &:nth-child(2) {
      text-align: center;
      .el-progress {
        margin: 0 auto;
      }
    }
  }
}
.App {
  .el-col {
    cursor: pointer;
    margin-bottom: 10px;
    p {
      line-height: 150%;
      margin-bottom: 5px;
    }
    &:first-child {
      text-indent: 0%;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
