/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:42:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 23:17:08
 */
<template>
  <div class="bgc_f5f5f5 live_pageSize">
    <Header></Header>
    <div class="live_height">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </div>
    <SuS></SuS>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/header1";
import Footer from "@/components/footer1";
import SuS from '@/views/PCshop/components/suspen' //悬浮

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    SuS
  },
};
</script>

<style lang="less" scoped>
.live_pageSize {
  // width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  .live_height {
    min-width: 1200px;
    min-height: calc(100vh - 170px);
  }
}
</style>
